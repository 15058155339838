<template>
    <div class="search formBox">
        <div class="input">
            <div class="label">行政区划</div>
            <el-cascader size="medium" v-model="region" style="width: 200px" :options="regions" placeholder="行政区划" :props="{ expandTrigger: 'hover' }" @change="dsqx" collapse-tags clearable>
            </el-cascader>
        </div>
        <div class="input">
            <div class="label">建设类别</div>
            <el-select @change="changeJslb" v-model="searchType" style="width: 200px" slot="prepend" placeholder="请选择">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="item in layers" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
        </div>

        <div class="input">
            <div class="label">项目性质</div>
            <el-cascader size="medium" style="width: 200px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
            </el-cascader>
        </div>
        <div class="input">
            <div class="label">建设性质</div>
            <el-cascader size="medium" style="width: 200px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
            </el-cascader>
        </div>
        <div class="input">
            <div class="label">项目功能</div>
            <el-cascader size="medium" style="width: 200px" v-model="checkedXmgn" :options="xmgnList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
            </el-cascader>
        </div>
        <div class="input">
            <div class="label">项目名称</div>
            <el-input style="width:200px" v-model="searchText" placeholder="请输入"></el-input>
        </div>
        <el-button type="primary" style="margin-top:17px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
        <el-button type="primary" style="margin-top:17px" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
        <!-- <el-input placeholder="请输入搜索内容" v-model="searchText" class="input-with-select">
            <el-select v-model="searchType" slot="prepend" placeholder="请选择" style="width:135px">
                <el-option label="建设类别(全部)" value=""></el-option>
                <el-option v-for="item in layers" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
            111
            <el-cascader size="medium" v-model="checkedXmxz" :options="xmxzList" placeholder="项目性质" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
            </el-cascader>
            <el-button slot="append" @click="search" icon="el-icon-search"></el-button>
        </el-input> -->
    </div>
</template>
<script>
export default {
    mounted() {
        this.getRegion();

    },
    methods: {
        dsqx(val) {
            var x, y;
            this.regions.map((r) => {
                if (r.label == val[0]) {
                    r.children.map((rr) => {
                        if (rr.label == val[1]) {
                            x = rr.x;
                            y = rr.y;
                        }
                    });
                }
            });
            // console.log(x,y);
            this.$parent.locDsQx(x,y);
        },
        clearCondition() {
            this.region = [""];
            this.searchType = "";
            this.searchText = "";
            this.checkedXmxz = [];
            this.checkedJsxz = [];
            this.checkedXmgn = [];
        },
        search() {
            // if (this.searchText.trim() == "") {
            //   this.$toastr("请输入搜索内容");
            //   return false;
            // }
            var layerids = [];
            // if (this.searchType != "") {
            //     layerids = [this.searchType];
            // } else {
            //     this.layers.forEach(function (v) {
            //         layerids.push(v.value);
            //     });
            // }
            var ssds = "";
            var ssx = "";
            if (this.region.length > 0) {
                ssds = this.region[0];
                if (this.region.length > 1) {
                    ssx = this.region[1];
                }
            }
            //所属地市
            var query_ssds = {
                Name: "SSDS",
                Value: ssds,
                DisplayType: "Equal",
            };

            //所属县
            var query_ssx = {
                Name: "SSX",
                Value: ssx,
                DisplayType: "Equal",
            };
            var query_xmmc = {
                Name: "XMMC",
                Value: this.searchText,
                DisplayType: "like",
            };
            //建设类别
            var query_gllx = {
                Name: "GLLX",
                Value: this.searchType,
                DisplayType: "Equal",
            };
            //项目性质
            var query_xmxz = {
                Name: "XMXZ",
                Value: this.checkedXmxz.join(","),
                DisplayType: "checkbox",
            };
            //建设性质
            var query_jsxz = {
                Name: "JSXZ",
                Value: this.checkedJsxz.join(","),
                DisplayType: "checkbox",
            };
            //项目功能
            var query_xmgn = {
                Name: "XMGN",
                Value: this.checkedXmgn.join(","),
                DisplayType: "checkbox",
            };
            var postData = {
                page: 1,
                rows: 1000,
                Sort: "XMPX",
                Order: "asc",
                wheres: JSON.stringify([
                    query_xmmc,
                    query_gllx,
                    query_xmxz,
                    query_jsxz,
                    query_xmgn,
                    query_ssds,
                    query_ssx,
                ]),
            };
            this.$parent.doFind(layerids, this.searchText.trim(), postData);
        },
        getRegion() {
            var postData = { SSDS: "", SSX: "" };
            this.http
                .post("/api/Base_area/getAdminDivTree", postData)
                .then((res) => {
                    // console.log(res,456);
                    res.map((r) => {
                        r.value = r.label;
                        r.children.map((rr) => {
                            rr.value = rr.label;
                        });
                    });
                    this.regions = res;
                });
        },
        changeJslb(val){
            console.log(val)
            switch (val) {
                case '高速公路':
                    this.xmxzList = [
                        {
                            value: "“十三五”续建项目",
                            label: "“十三五”续建项目",
                            disabled: false,
                        },
                        {
                            value: "2021年新开工项目",
                            label: "2021年新开工项目",
                            disabled: false,
                        },
                        {
                            value: "“十四五”谋划项目",
                            label: "“十四五”谋划项目",
                            disabled: false,
                        },
                        {
                            value: "“十四五”其他项目",
                            label: "“十四五”其他项目",
                            disabled: false,
                        },
                    ]
                    break;
                case '普通国道':
                    this.xmxzList = [
                        {
                            value: "“十三五”续建项目",
                            label: "“十三五”续建项目",
                            disabled: false,
                        },
                        {
                            value: "“十四五”新开工项目",
                            label: "“十四五”新开工项目",
                            disabled: false,
                        },
                        {
                            value: "谋划项目",
                            label: "谋划项目",
                            disabled: false,
                        },
                    ]
                    break;
                case '普通省道':
                    this.xmxzList = [
                        {
                            value: "“十三五”续建项目",
                            label: "“十三五”续建项目",
                            disabled: false,
                        },
                        {
                            value: "“十四五”新开工项目",
                            label: "“十四五”新开工项目",
                            disabled: false,
                        },
                        {
                            value: "“十四五”备选项目",
                            label: "“十四五”备选项目",
                            disabled: false,
                        },
                    ]
                    break;
                case '':
                    this.xmxzList = [
                        {
                            value: "“十三五”续建项目",
                            label: "“十三五”续建项目",
                            disabled: false,
                        },
                        {
                            value: "“十四五”谋划项目",
                            label: "“十四五”谋划项目",
                            disabled: false,
                        },
                        {
                            value: "“十四五”新开工项目",
                            label: "“十四五”新开工项目",
                            disabled: false,
                        },
                        {
                            value: "谋划项目",
                            label: "谋划项目",
                            disabled: false,
                        },
                        {
                            value: "2021年新开工项目",
                            label: "2021年新开工项目",
                            disabled: false,
                        },
                        {
                            value: "“十四五”其他项目",
                            label: "“十四五”其他项目",
                            disabled: false,
                        },
                    ]
                    break;
            }
        }
    },
    data() {
        return {
            region: [""], //已选中行政区划
            regions: [
                {
                    value: "",
                    label: "黑龙江省",
                },
            ],
            checkedXmgn: [],
            xmgnList: [
                { value: "出省通道", label: "出省通道", disabled: false },
                {
                    value: "区域内连通农林场、乡镇、景区",
                    label: "区域内连通农林场、乡镇、景区",
                    disabled: false,
                },
                { value: "城市过境段", label: "城市过境段", disabled: false },
                { value: "瓶颈路段", label: "瓶颈路段", disabled: false },
                { value: "砂石路", label: "砂石路", disabled: false },
                { value: "跨区域通道", label: "跨区域通道", disabled: false },
                {
                    value: "重要节点连接国省干线",
                    label: "重要节点连接国省干线",
                    disabled: false,
                },
            ],
            checkedJsxz: [],
            jsxzList: [
                { value: "新建", label: "新建", disabled: false },
                { value: "升级改造", label: "升级改造", disabled: false },
                { value: "原级改造", label: "原级改造", disabled: false },
                { value: "路面改造", label: "路面改造", disabled: false },
                { value: "改扩建", label: "改扩建", disabled: false },
            ],
            checkedXmxz: [],
            xmxzList: [
                {
                    value: "“十三五”续建项目",
                    label: "“十三五”续建项目",
                    disabled: false,
                },
                {
                    value: "“十四五”谋划项目",
                    label: "“十四五”谋划项目",
                    disabled: false,
                },
                {
                    value: "“十四五”新开工项目",
                    label: "“十四五”新开工项目",
                    disabled: false,
                },
                {
                    value: "谋划项目",
                    label: "谋划项目",
                    disabled: false,
                },
                {
                    value: "2021年新开工项目",
                    label: "2021年新开工项目",
                    disabled: false,
                },
                {
                    value: "“十四五”其他项目",
                    label: "“十四五”其他项目",
                    disabled: false,
                },
            ],
            searchText: "",
            searchType: "",
            layers: [
                { name: "高速公路", value: "高速公路" },
                { name: "普通国道", value: "普通国道" },
                { name: "普通省道", value: "普通省道" },
            ],
        };
    },
};
</script>
<style lang="less" scoped>
.input {
    margin-right: 10px;
}
.el-button {
    height: 36px !important;
    line-height: 1 !important;
}
.search {
    /* width: 400px; */
    display: flex;
    // flex-flow: wrap;
}
.el-select {
    // width: 150px;
}
/deep/ .el-input__inner {
    background-color: #fff;
    // width: 150px;
    height: 36px !important;
}
/deep/ .el-input {
    // width: 150px !important;
}
.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

/* input {
  width: 200px;
  height: 30px;
}
button {
  margin-left: 10px;
  height: 30px;
  width: 60px;
} */
</style>
