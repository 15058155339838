<template>
    <div>
        <el-table @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="small " :height="tableHeight" border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
            <el-table-column label="操作" :width="130" align="center">
                <template slot-scope="scope">
                    <div style="display: flex;align-items: center">
                        <span style="display: flex;align-items: center" class="newicon  iconsyes"  @click="openDetailsEdit(scope.row)"><i class="el-icon-postcard icons iconsyes" style="font-size: 18px"></i>详情</span>
                        <span @click="downLoadZip(scope.row)" class="newicon  iconsyes" style="display: flex;align-items: center;color: #409eff"><img style="margin-right: 0;width: 18px;height: 14px"  src="../../../assets/images/interactive/download.png" alt="">下载</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="SSDS" label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column>
            <el-table-column prop="SSX" label="所在县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>
            <el-table-column prop="XMMC" label="项目名称" align="center" show-overflow-tooltip :width="320"></el-table-column>
            <el-table-column prop="LXBH" label="路线编码" align="center" show-overflow-tooltip ></el-table-column>
            <el-table-column prop="JSLB" label="建设类别" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="JSXL" label="建设序列" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="JSXZ" label="建设性质" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="ZRZYJ" label="自然资源局" align="center" show-overflow-tooltip>
                    <template slot-scope="scope" v-if="scope.row.ZRZYJFJ && scope.row.ZRZYJFJ.length!= 0">
                        <img style="width: 24px" @click="exportFile(scope.row.ZRZYJFJ)" src="../../../assets/images/interactive/cloud.png" alt="">
                    </template>
            </el-table-column>
            <el-table-column prop="QRH" label="确认函" align="center" show-overflow-tooltip>
                <template slot-scope="scope" v-if="scope.row.DRHFJ  && scope.row.DRHFJ.length!= 0">
                    <img style="width: 24px" @click="exportFile(scope.row.DRHFJ)" src="../../../assets/images/interactive/cloud.png" alt="">
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        <el-drawer :visible.sync="showCard" :title="cardTitle" size="1133px" :inline="true">
            <el-form :inline="true" :model="detail" class="demo-form-inline">
                <div class="wrap">
                    <table style="text-align:center" >
                        <tr>
                            <td>
                                <div class="label2">项目名称</div>
                            </td>
                            <td colspan="3">
                                <div>{{detail.XMMC}}</div>
                            </td>
                            <td>
                                <div class="label2">路线编号</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.LXBH}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label2">所在地市</div>
                            </td>
                            <td style="width: 220px">
                                <div class="cont">{{detail.SSDS}}</div>
                            </td>
                            <td>
                                <div class="label2">所属县（市）</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.SSX}}</div>
                            </td>
                            <td>
                                <div class="label2">项目性质</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.XMXZ}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label2">建设性质</div>
                            </td>
                            <td>
                                <div class="cont">
                                    <div class="cont">{{detail.JSXZ}}</div>

                                </div>
                            </td>
                            <td>
                                <div class="label2">项目类型</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.XMLX}}</div>
                            </td>
                            <td>
                                <div class="label2">建设类别</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.XMLX}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label2">开工年</div>
                            </td>
                            <td>
                                <el-form-item>
                                    <div class="cont">{{detail.KGN}}</div>
                                </el-form-item>
                            </td>
                            <td>
                                <div class="label2">完工年</div>
                            </td>
                            <td>
                                <el-form-item>
                                    <div class="cont">{{detail.WGN}}</div>
                                </el-form-item>
                            </td>
                            <td>
                                <div class="label2">建设规模高速（公里）</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.JSGMGS}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label2">建设规模一级（公里）</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.JSGMYJ}}</div>

                            </td>
                            <td>
                                <div class="label2">建设规模二级（公里）</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.JSGMEJ}}</div>

                            </td>
                            <td>
                                <div class="label2">建设规模三级（公里）</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.JSGMSJ}}</div>

                            </td>
                        </tr>
                    </table>
                    <div class="flex" style="margin: 18px 0 12px">
                        <div class="slfj">确认函</div>
                    </div>
                    <table style="text-align:center" >
                        <tr>
                            <td>
                                <div class="label2"></div>
                            </td>
                            <td class="label3">
                                是否确认
                            </td>
                            <td class="label3" style="width: 771px">
                                <div >公路用地图边线DXF格式电子版文件</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="label2">
                                <div >自然资源局</div>
                            </td>
                            <td>
                                <el-form-item>
                                    <template>
                                        <el-checkbox text-color="#666666" v-model="ZRZYJSFQR">已确认</el-checkbox>
                                    </template>
                                </el-form-item>
                            </td>
                            <td>
                                <div class="flex fjWrap">
                                    <el-form-item>
                                        <label class="fright">
                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                            <input style="width: 72px" type="file" v-show="false" multiple="multiple" name="file" ref="file" id="fileInput" @change="onSubmit($event,'ZRZYJFJ')" />
                                        </label>
                                    </el-form-item>
                                    <div>
                                        <div class="file" v-for="(item,index) in detail.ZRZYJFJ" :key="index">
                                            <a target="view_window" :href="baseUrl+item.url+item.name">
                                                <img src="../../../assets/images/ic-fj.png" alt=""><span  style="margin-right: 6px;color: #409eff">{{item.name}} </span>
                                            </a>
                                            <i style="color: #666666" class="el-icon-delete" @click.stop="deleted(index,'ZRZYJFJ')"></i>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="label2">
                                <div >确认函</div>
                            </td>
                            <td>
                                <el-form-item>
                                    <template>
                                        <el-checkbox text-color="#666666" v-model="QRHSFQR">已确认</el-checkbox>
                                    </template>
                                </el-form-item>
                            </td>
                            <td>
                                <div class="flex fjWrap">
                                    <el-form-item>
                                        <label class="fright">
                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                            <input style="width: 72px" type="file" v-show="false" multiple="multiple" name="file" ref="file" id="fileInput" @change="onSubmit($event,'DRHFJ')" />
                                        </label>
                                    </el-form-item>
                                    <div>
                                        <div class="file" v-for="(item,index) in detail.DRHFJ" :key="index">
                                            <a target="view_window" :href="baseUrl+item.url+item.name">
                                                <img src="../../../assets/images/ic-fj.png" alt=""><span  style="margin-right: 6px;color: #409eff">{{item.name}} </span>
                                            </a>
                                            <i style="color: #666666" class="el-icon-delete" @click.stop="deleted(index,'DRHFJ')"></i>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </el-form>
            <span class="dialog-footer">
                    <el-button type="primary" @click="saveDetail">保存</el-button>
                    <el-button  @click="showCard = false">取消</el-button>
                  </span>
        </el-drawer>
    </div>
</template>

<script>
    import store from "../../../store";

    export default {
        name: "Carding",
        props:{
            params: { type: Object },
        },
        data(){
            return{
                QRHSFQR: false,
                ZRZYJSFQR: false,
                baseUrl: "http://82.156.50.94:8002/",
                checked: false,
                detail:{},
                param:{},
                tableHeight: document.body.offsetHeight - 220, //表格高度
                tableData:[],
                region: [""], //已选中行政区划
                regions: [
                    {
                        value: "",
                        label: "黑龙江省",
                    },
                ],
                pageSize: 30, //每页记录数
                currentPage:1,
                total:0,
                cardTitle:'',
                showCard: false,
            }
        },
        watch:{
            params:{
                handler(val){
                    this.param = val
                   this.search()
                },
                immediate: true
            }
        },
        mounted() {

        },
        methods:{
            exportFile(url) {
                var a = this.dealFj(url)
                console.log(a)

                var that = this
                a.map((item,index)=>{
                    this.http.post('/api/Land_road/ExportFile?fileName='+item.url+item.name,{}).then(res=> {
                        if (!res.status) {
                            this.$message.error(res.message)
                            return false
                        }
                        let path = "/api/View_Land_road/DownLoadFile";
                        path = path[0] == "/" ? path.substring(1) : path;
                        console.log(path)
                        var timestamp = new Date().getTime();
                        console.log(that.baseUrl + path + "?path=" + res.data)
                        this.download(
                            that.baseUrl + path + "?path=" + res.data,
                            item.name
                        )
                    })
                })

            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Land_road_confirm/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Land_road_confirm' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                console.log(file)
                let form = new FormData();
                let arrs = [];
                console.log(file);
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/View_land_road_confirm/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            this.detail[type].push({url:res.data, name:file[0].name})

                        });
                    });

            },
            //附件处理
            dealFj(data){
                console.log(data)
                var a = []
                if(data != null && data != undefined && data != ''){
                    data = data.split(';')
                    data.map((subItem)=>{
                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                        a.push(subItem)
                    })
                    return  a
                }else {
                    return  []
                }
            },
            dealSaveFj(data){
                var pfwjString = []
                data.map((item)=>{
                    pfwjString.push(item.url+item.name)
                })
                console.log( pfwjString.join(';'))
                return pfwjString.join(';')
            },
            saveDetail() {
                var params = JSON.parse(JSON.stringify(this.detail))
                params.DRHFJ =this.dealSaveFj(params.DRHFJ)
                params.ZRZYJFJ =this.dealSaveFj(params.ZRZYJFJ)
                params.QRHSFQR = this.QRHSFQR ? 1 : 0 // 1 已确认  0 未确认
                params.ZRZYJSFQR =this.ZRZYJSFQR ? 1 : 0 // 1 已确认  0 未确认
                console.log(params,params.ID )
                if(params.ID == null){
                            this.http.post('/api/Land_road_confirm/AddLandRoadConfirm',params).then(res=>{
                                console.log(res)
                                if(res.status){
                                    this.$message.success('保存成功')
                                    this.showCard = false
                                    this.search()
                                }else{
                                    this.$message.error(res.message)

                                }
                            })
                }else{
                            this.http.post('/api/Land_road_confirm/UpdateLandRoadConfirm',params).then(res=>{
                                console.log(res)
                                if(res.status){
                                    this.showCard = false
                                    this.$message.success('保存成功')
                                    this.search()
                                }else{
                                    this.$message.error(res.message)

                                }
                            })
                }


            },
            getRegion() {
                var postData = { SZDS: "", SZX: "" };
                this.http.post("/api/Land_road_confirm/getRegion", postData).then((res) => {
                    //遍历地市
                    res.forEach((v) => {
                        var item = {};
                        var qxList = [];
                        //遍历区县
                        for (var i = 0; i < v.length; i++) {
                            var qx = v[i];
                            //设置地市名称
                            if (i == 0) {
                                item.value = qx.szds;
                                item.label = qx.szds;
                                qxList.push({ value: "", label: "全部" });
                            }

                            qxList.push({ value: qx.szx, label: qx.szx });
                            item.children = qxList;
                        }
                        this.regions.push(item);
                    });
                });
            },
            //查询
            search() {
                // this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.http.post("/api/View_land_road_confirm/GetPageData", this.param, "正在请求数据，请稍候...").then((res) => {
                    this.regions =  [{value: "", label: "黑龙江省"}]
                    this.getRegion()
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;

                    }
                });
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.params.rows = val
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.params.page = val
                this.search();
            },
            openDetailsEdit(row){
                this.ZRZYJSFQR = false
                this.QRHSFQR = false
                this.detail.DRHFJ = []
                this.detail.ZRZYJFJ = []
                    this.cardTitle = row.XMMC;
                if(row.ID){
                    this.http.post('/api/Land_road_confirm/getItemById?id='+row.ID,{}).then(res=>{
                        console.log(res)
                        this.detail = res
                        this.detail.DRHFJ = this.dealFj(this.detail.DRHFJ)
                        this.detail.ZRZYJFJ = this.dealFj(this.detail.ZRZYJFJ)
                        this.ZRZYJSFQR =  this.detail.ZRZYJSFQR == 1 ? true : false
                        this.QRHSFQR =  this.detail.QRHSFQR == 1 ? true : false
                    })
                }else{
                    this.detail = row
                    this.detail.DRHFJ = []
                    this.detail.ZRZYJFJ = []
                }
                this.showCard = true;

                console.log(row)
            },
        }
    }
</script>


<style scoped lang="less">
    .fl-jus{
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label{
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #FAFAFA;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2{
        width: 145px;
        height: 36px;
        background: #FAFAFA;
        line-height: 36px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3{
        width: 185px;
        height: 36px;
        background: #FAFAFA;
        line-height: 36px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont{
        width: 220px;
        height: 36px;
        text-align: center;
        line-height: 36px;
    }
    body{
        background: #ffffff !important;
    }
    .flex{
        display: flex;
        align-items: center;
    }
    .plant{
        width: 100%;
        height: 50px;
        background: #06A7FF;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        .top{
            height: 50px;
            .logo{
                width: 32px;
                height: 32px;
                margin:0 12px 0 20px;
                img{
                    width: 32px;
                    height: 32px;
                }
            }
            .title{
                width: 360px;
                height: 26px;
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                color: #FFFFFF;
                opacity: 1;
                margin-right: 151px;
            }
            .nav{
                cursor: pointer;
                margin-right: 65px;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.88);
            }
            .active{
                font-weight: bold;
                color: #FFFFFF;
            }
        }
        .shutDown{
            font-size: 20px;
            margin-right: 18px;
            color: #ffffff;
        }
    }
    .zjyj{
        font-size: 12px;
        font-weight: bold;
        width: 52px;
        height: 20px;
        background: #67C23A;
        border-radius: 12px;
        line-height: 20px;
        color: #ffffff;
    }
    .edit{
        padding-right: 15px;
        border-right: 1px solid #707070 ;
        font-size: 16px;
        color: #409EFF;
    }
    .delete{
        font-size: 16px;
        color: #F56C6C;
    }
    .dialog-footer{
        width: 100%;
        height: 47px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        background: #F8F8F8;
        align-items: center;
        justify-content: center;
    }
    .labels{
        margin:0 auto;
        width: 27px;
        height: 20px;
        position: relative;
        border-radius: 2px;
        &::after{
            content:"";
            width: 0;
            height: 0;
            border-radius: 2px;
            border-top: 10px solid transparent;
            border-right: 8px solid #fff;
            border-bottom: 10px solid transparent;
            position: absolute;
            top:0;
            left:19px;
        }
    }
    /deep/.el-color-picker {
        line-height: 60px;
    }
    /deep/.el-color-picker__color{
        border: none;
    }
    /deep/.el-color-picker__trigger{
        margin:0 auto;
        width: 35px;
        height: 20px;
        position: relative;
        border-radius: 2px;
        padding: 0;
        border: none;
        &::after{
            content:"";
            width: 0;
            height: 0;
            border-radius: 2px;
            border-top: 10px solid transparent;
            border-right: 8px solid #fff;
            border-bottom: 10px solid transparent;
            position: absolute;
            top:0;
            right:0px;
        }
    }
    .bigLabels{
        margin-right: 13px;
        padding: 0px 15px 0px 6px;
        height: 20px;
        line-height: 20px;
        position: relative;
        border-radius: 2px;
        color: #ffffff;
        margin-top: 4px;
        &::after{
            content:"";
            width: 0;
            height: 0;
            border-radius: 2px;
            border-top: 10px solid transparent;
            border-right: 8px solid #fff;
            border-bottom: 10px solid transparent;
            position: absolute;
            top:0;
            right:0px;
        }
    }
    .wrap{
        padding: 17px 19px;
        /deep/.el-form-item{
            margin: 0;
        }
        /deep/.el-input__inner{
            height: 32px;
        }
        /deep/.el-input--medium .el-input__inner{
            height: 32px;
            line-height: 32px;
        }
        table {
            border-collapse: collapse;
        }
        td {
            border: 1px solid #dddddd;
            .cont{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .slfj{
        text-align: center;
        width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        border-radius: 0px 16px 16px 0px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        line-height: 32px;
        margin-right: 17px;
    }
    .star{
        font-size: 14px;
        font-weight: bold;
        color: #F56C6C;
    }
    .fjWrap{
        padding: 3px 13px;
        align-items: flex-start;
        .fright{
            margin-right: 12px;
        }
    }
    .file{
        margin-right: 10px;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    /deep/.el-checkbox__inner{
        width: 18px;
        height: 18px;
        border-radius: 4px;
        &::after{
            left: 6px;
            top: 3px;
        }
    }
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label{
        color: #666;
    }
</style>
