<template>
    <div class="top_ri">
        <div class="tools" @click.stop="tools_select = !tools_select" :class="{ color_tools: !tools_select }">
            <i v-if="tools_select">
                <img src="@/assets/mapimages/newmaptools/tools_no.png" alt />
            </i>
            <i v-else>
                <img src="@/assets/mapimages/newmaptools/tools_select.png" alt />
            </i>
            <span>工具箱</span>
            <i v-if="tools_select">
                <img src="@/assets/mapimages/newmaptools/zhankai.png" alt />
            </i>
            <i v-else>
                <img src="@/assets/mapimages/newmaptools/shouqi.png" alt />
            </i>
        </div>
        <div id="widget" :class="{ active_tool1: tools_select }">
            <div class="action-button" title="全屏" id="allScreenButton" @click="goTo">
                <img src="@/assets/mapimages/newmaptools/allScreen.png" />
            </div>
            <div class="action-button" title="地图移动" id="moveButton" @click="mapMove">
                <img v-if="mouseState == 1" src="@/assets/mapimages/newmaptools/move-selected.png" />
                <img v-else src="@/assets/mapimages/newmaptools/move.png" />
            </div>
            <!-- <div class="action-button" title="地图选择" id="ChoiceButton" @click="Choiceof">
                <img v-if="mouseState == 2" src="@/assets/mapimages/newmaptools/Choice-selected.png" />
                <img v-else src="@/assets/mapimages/newmaptools/Choice.png" />
            </div> -->
         
            <!-- <div class="action-button" title="规划图层" id="ChoiceButton" @click="ChoicePlan">
                <img v-if="mouseState == 4" src="@/assets/mapimages/newmaptools/edit-selected.png" />
                <img v-else src="@/assets/mapimages/newmaptools/editmap.png" />
            </div> -->
            <div class="action-button" title="清屏" id="removeButton" @click="clearScreen">
                <img src="@/assets/mapimages/newmaptools/remove.png" />
            </div>
            <!-- <div class="action-button" title="面积" id="areaButton">
          <img src="@/assets/mapimages/newmaptools/area.png" />
        </div> -->
            <div class="action-button" title="测量长度或面积" @click="toolbarDiv">
                <img src="@/assets/mapimages/newmaptools/distance.png" />
            </div>
            <!-- <div class="action-button" title="测量桩号" @click="mapMeasure" id="measureButton">
                <img v-if="mouseState == 3" src="@/assets/mapimages/newmaptools/measure-selected.png" />
                <img v-else src="@/assets/mapimages/newmaptools/measure.png" />
            </div> -->
            <!-- <div class="action-button" title="桩号定位" id="pileNumber " @click="PilePoint">
                <img src="@/assets/mapimages/newmaptools/pileNumber.png" />
            </div>
            <div class="action-button" title="坐标定位" id="coordinateButton" @click="coordinate">
                <img src="@/assets/mapimages/newmaptools/coordinate.png" />
            </div> -->
            <!-- <div class="action-button" title="图例" @click="showLegend()">
                <img src="@/assets/mapimages/newmaptools/legend.png" />
            </div> -->
            <!-- <div class="action-button" title="图层选择" id="coordinateButton" @click="showLayer">
                <img src="@/assets/mapimages/newmaptools/show.png" />
            </div> -->
   <div class="action-button" title="路线" id="coordinateButton" @click="showGsd">
                <img v-if="mouseState == -1" src="@/assets/mapimages/newmaptools/gsd-selected.png" />
                <img v-else src="@/assets/mapimages/newmaptools/gsd.png" />
            </div>
            <div class="action-button" title="路况" id="coordinateButton" @click="showLk">
                <img src="@/assets/mapimages/newmaptools/lkxz.jpg" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tools_select: true, //工具栏显隐
            toolbarDivShow: false, //测量工具栏
            layerStyleControlShow: false, //图层选择
            mouseState: 1, //鼠标状态 1移动 2选择 3测量桩号
        };
    },
    methods: {
        clearScreen() {
            this.$parent.clearScreen();
            this.mouseState = 1;
        },
        mapMove() {
            this.mouseState = 1;
            this.$parent.mapMove();
        },
        Choiceof() {
            this.mouseState = 2;
            this.$parent.Choiceof();
        },
        ChoicePlan() {
            this.mouseState = 4;
            this.$parent.ChoicePlan();
        },
        mapMeasure() {
            this.mouseState = 3;
            this.$parent.mapMeasure();
        },
        showLegend() {
            this.$parent.showLegend();
        },
        PilePoint() {
            this.$parent.PilePointShow = true;
        },
        coordinate() {
            this.$parent.coordinateShow = true;
        },
        showLk() {
            this.$emit("showLk");
            this.mouseState = 1;
            this.$parent.showLk();
        },
        showGsd() {
            this.mouseState = this.mouseState != -1 ? -1 : 1;
            this.$parent.showGSD();
        },
        showLayer() {
            this.layerStyleControlShow = !this.layerStyleControlShow;
            this.$parent.layerStyleControlShow = this.layerStyleControlShow;
        },
        toolbarDiv() {
            this.toolbarDivShow = !this.toolbarDivShow;
            this.$parent.toolbarDivShow = this.toolbarDivShow;
        },
        //全屏
        goTo() {
            this.mouseMenuState("none");
            this.$parent.goTo();
        },
        //控制鼠标右键菜单存不存在
        mouseMenuState(state) {
            var mouseMenu = document.getElementById("MapMouse");
            if (mouseMenu) {
                mouseMenu.style = "display:" + state;
            }
        },
    },
};
</script>
<style  scoped lang="less">
.top_ri {
    position: absolute;
    top: 93px;
    right: 120px;
    box-shadow: 0px 0px 4px 0px rgba(12, 12, 12, 0.15);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 3px;
    box-sizing: border-box;
    height: 36px;

    .color_tools {
        color: rgba(30, 104, 229, 1) !important;
    }

    .tools {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        color: rgba(95, 110, 158, 1);

        i {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }

        span {
            margin-right: 5px;
        }
    }

    #widget {
        // opacity: 0.7;
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        width: 430px;
        transition: width 0.7s ease-in-out;
        overflow: hidden;
        border-left: 1px solid rgba(0, 0, 0, 0.1);

        img {
            width: 20px;
            height: 20px;
            opacity: 0.8;
        }

        .action-button {
            top: 50px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img:hover,
        img:focus {
            opacity: 1 !important;
            //  background: chartreuse;
        }

        div {
            margin-left: 10px;
        }
    }

    .active_tool {
    }

    .active_tool1 {
        width: 0 !important;
        border: none !important;
    }

    #data_tab {
        width: 90px;
        height: 40px;
        background: white;
        box-shadow: 0px 0px 4px 0px rgba(12, 12, 12, 0.33);
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
    }

    #user {
        width: 40px;
        height: 40px;
        background: white;
        margin-left: 20px;
    }
}
</style>
