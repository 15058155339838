<template>

    <div class="plant">
        <div class="flex" style="justify-content: space-between">
            <div class="top flex">
                <div class="logo"><img src="../assets/images/interactive/logo.png" alt=""></div>
                <div class="title">公路基础设施国土空间控制规划交互平台</div>
                <div class="tab flex">
                    <div v-for="(item,index) in tab" @click="changeTab(index)" :key="index" class="nav" :class="index == active ? 'active' : 'normal'">{{item}}</div>
                </div>
            </div>
            <!-- <div class="shutDown" @click="$router.go(-1)"><i class="el-icon-switch-button"></i></div> -->
            <div class="shutDown" @click="closeWin()"><i class="el-icon-switch-button" style="cursor: pointer;"></i></div>
        </div>
        <div>
            <el-row v-if="active ==0 || active == 3">
                <el-col :span="24">
                    <div class="box">
                        <div class="box-content">
                            <el-form  :inline="true" class="form-inline formBox">
                                <el-form-item label="行政区划">
                                    <el-cascader style="width: 200px" size="medium" v-model="region" :options="regions" :props="{ expandTrigger: 'hover' }" @change="handleChange" clearable></el-cascader>
                                </el-form-item>
                                <el-form-item label="项目名称">
                                    <el-input style="width: 200px" size="medium" v-model="xmmc" placeholder="请输入" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="建设类别">
                                    <el-cascader size="medium" style="width: 200px" v-model="checkedJslb" :options="jslbList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item label="建设序列">
                                    <el-cascader size="medium" style="width: 200px" v-model="checkedJsxl" :options="jsxlList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item label="建设性质">
                                    <el-cascader size="medium" style="width: 200px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item label="专家意见" v-if="active== 0">
                                    <el-cascader size="medium" style="width: 200px" v-model="checkedZjyj" :options="zjyjList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item label="确认函" v-if="active== 3">
                                    <el-cascader size="medium" style="width: 200px" v-model="checkedQrh" :options="qrhList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item v-if="active== 0" label="标签">
                                    <el-cascader size="medium" style="width: 200px" v-model="checkedLabel" :options="labelList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                    </el-cascader>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
<!--                                    <el-button type="primary" size="medium" @click="exportData"><i class="fa fa-upload"></i> 导出</el-button>-->
                                </el-form-item>
<!--                                <el-form-item class="fright">-->
<!--                                    <el-button type="primary" plain size="small" @click="activeIndex = !activeIndex">-->
<!--                                        {{ activeIndex ? "收起筛选" : "展开筛选" }}-->
<!--                                        <i :class="{ 'el-icon-arrow-up': activeIndex }"> </i>-->
<!--                                        <i :class="{ 'el-icon-arrow-down': !activeIndex }"> </i>-->
<!--                                    </el-button>-->
<!--                                </el-form-item>-->
                            </el-form>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row v-if="active ==0">
                <el-col :span="24">
                    <div class="box">
                        <div class="box-content">

                            <el-table @sort-change="changeSort" @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="small " :height="tableHeight" border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
                                <el-table-column fixed label="操作" :width="180" align="center">
                                    <template slot-scope="scope">
                                        <div style="display: flex;align-items: center">
                                            <span style="display: flex;align-items: center" class="newicon  iconsyes" :style="{ color : scope.row.XH == '' || scope.row.XH == undefined ? '#dddddd' : ''}"  @click="showMaps(scope.row)"><i  style="font-size: 18px"  class="el-icon-location icons"></i>占地</span>
                                            <span style="display: flex;align-items: center" class="newicon  iconsyes"  @click="openDetailsEdit(scope.row)"><i  style="font-size: 18px" class="el-icon-postcard icons"></i>详情</span>
                                            <span @click="openLabel(scope.row)" class="newicon  iconsyes" style="display: flex;align-items: center;color: #409eff"><img style="margin-right: 0;width: 17px;height:18px;transform: rotate(-90deg)" src="../assets/images/interactive/label.png"  alt="">标签</span>
                                        </div>
<!--                                        <i class="el-icon-postcard icons iconsyes" style="font-size: 18px" @click="openDetailsEdit(scope.row)">详情</i>-->
                                    </template>
                                </el-table-column>
                                <el-table-column fixed prop="SSDS" label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column>
                                <el-table-column fixed prop="SSX" label="所在县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>
                                <el-table-column fixed prop="XMMC" label="项目名称" align="center" show-overflow-tooltip :width="320"></el-table-column>
                                <el-table-column prop="XMLX" label="路线名称" align="center" :width="150" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="LXBM" label="路线编码" align="center" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="JSLB" label="建设类别" align="center" show-overflow-tooltip></el-table-column>
                                <el-table-column label="专家意见" align="center" show-overflow-tooltip>
                                    <template slot-scope="scope"  >
                                        <div class="zjyj"  :style="{background:scope.row.ZJYJ == 1 ? '#67c23a' : scope.row.ZJYJ == 2 ? '#F56C6C' : '#909399'}">
                                           {{scope.row.ZJYJ == 1 ? '通过' : scope.row.ZJYJ == 2 ? '不通过' : '未审核' }}
                                        </div>
                                    </template>
                                </el-table-column>
<!--                                <el-table-column label="规划选址" :width="160" align="center">-->
<!--                                    <el-table-column prop="GHDZZXX" label="中心线" width="70" align="center">-->
<!--                                        <template slot-scope="scope" v-if="scope.row.GHDZZXX && scope.row.GHDZZXX.length != 0">-->
<!--                                            <img style="width: 24px" @click="exportFile(scope.row.GHDZZXX)" src="../assets/images/interactive/cloud.png" alt="">-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->
<!--                                    <el-table-column prop="GHDZYDT" label="用地图" width="70" align="center">-->
<!--                                        <template slot-scope="scope" v-if="scope.row.GHDZYDT && scope.row.GHDZYDT.length != 0">-->
<!--                                            <img style="width: 24px" @click="exportFile(scope.row.GHDZYDT)" src="../assets/images/interactive/cloud.png" alt="">-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->
<!--                                </el-table-column>-->
<!--                                <el-table-column label="工可" :width="160" align="center">-->
<!--                                    <el-table-column prop="ZXX" label="中心线" width="70" align="center">-->
<!--                                        <template slot-scope="scope" v-if="scope.row.GKYZXX && scope.row.GKYZXX.length != 0">-->
<!--                                            <img style="width: 24px" @click="exportFile(scope.row.GKYZXX)" src="../assets/images/interactive/cloud.png" alt="">-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->
<!--                                    <el-table-column prop="YDT" label="用地图" width="70" align="center">-->
<!--                                        <template slot-scope="scope" v-if="scope.row.GKYYDT && scope.row.GKYYDT.length != 0">-->
<!--                                            <img style="width: 24px" @click="exportFile(scope.row.GKYYDT)" src="../assets/images/interactive/cloud.png" alt="">-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->
<!--                                </el-table-column>-->
<!--                                <el-table-column label="初步设计" :width="160" align="center">-->
<!--                                    <el-table-column prop="ZXX" label="中心线" width="70" align="center">-->
<!--                                        <template slot-scope="scope" v-if="scope.row.CBSJZXX && scope.row.CBSJZXX.length != 0">-->
<!--                                            <img style="width: 24px" @click="exportFile(scope.row.CBSJZXX)" src="../assets/images/interactive/cloud.png" alt="">-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->
<!--                                    <el-table-column prop="YDT" label="用地图" width="70" align="center">-->
<!--                                        <template slot-scope="scope" v-if="scope.row.CBSJYDT && scope.row.CBSJYDT.length != 0">-->
<!--                                            <img style="width: 24px" @click="exportFile(scope.row.CBSJYDT)" src="../assets/images/interactive/cloud.png" alt="">-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->
<!--                                </el-table-column>-->
<!--                                <el-table-column label="施工图设计" :width="160" align="center">-->
<!--                                    <el-table-column prop="ZXX" label="中心线" width="70" align="center">-->
<!--                                        <template slot-scope="scope" v-if="scope.row.SGTSJZXX && scope.row.SGTSJZXX.length != 0">-->
<!--                                            <img style="width: 24px" @click="exportFile(scope.row.SGTSJZXX)" src="../assets/images/interactive/cloud.png" alt="">-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->
<!--                                    <el-table-column prop="YDT" label="用地图" width="70" align="center">-->
<!--                                        <template slot-scope="scope" v-if="scope.row.SGTSJYDT && scope.row.SGTSJYDT.length != 0">-->
<!--                                            <img style="width: 24px" @click="exportFile(scope.row.SGTSJYDT)" src="../assets/images/interactive/cloud.png" alt="">-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->
<!--                                </el-table-column>-->
                                <el-table-column prop="TagList" label="标签" :width="275" align="center" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <div style="display: flex;align-items: center;flex-wrap: wrap">
                                            <div class="bigLabels" :style="{background: item.BQYS}" v-for="(item,index) in scope.row.TagList" :key="index">
                                                {{item.BQWZ}}
                                            </div>
                                        </div>
                                    </template>

                                </el-table-column>
                            </el-table>
                            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row v-if="active ==1">
                <el-col :span="24">
                    <div class="box">
                        <div class="box-content">
                            <PlanForm ></PlanForm>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row v-if="active ==2">
                <el-col :span="24">
                    <div class="box">
                        <div class="box-content">
                            <ZJJY ></ZJJY>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row v-if="active ==3">
                <el-col :span="24">
                    <div class="box">
                        <div class="box-content">
                            <Carding ref="mychild" :params="params"></Carding>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row v-if="active ==4">
                <el-col :span="24">
                    <div class="box">
                        <div  :style="{height:tagHeight+130+'px'}">
                            <compareMap ></compareMap>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!--        标签列表-->
        <el-drawer :visible.sync="showLabel" title="项目标签" size="479px" :inline="true">
            <el-main>
                <el-button style="float: right;margin-bottom: 10px" type="success" icon="el-icon-plus" @click="addLabel">添加</el-button>
                <el-table border ref="multipleTable" :data="labelList" style="width: 100%" @selection-change="selectLabel" :max-height=tagHeight>
                    <el-table-column type="selection" width="47" />
                    <el-table-column width="100px" prop="color" label="标签颜色" align="center">
                        <template slot-scope="scope">
                            <div class="labels" :style="{background:scope.row.BQYS}"></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="BQWZ" label="标签文字"  align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column label="操作" show-overflow-tooltip align="center">
                        <template slot-scope="scope">
                            <i class="edit el-icon-edit" @click="chooseColor(scope.row)"></i>
                            <el-popconfirm title="是否删除此条信息?" @confirm="deletedLabel(scope.row)">
                                <template #reference>
                                    <el-button style="margin-left: 12px" type="text" size="small" color="#d6000f"><i class="el-icon-delete delete"></i></el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <span class="dialog-footer">
                    <el-button type="primary" @click="saveLable">保存</el-button>
                    <el-button @click="showLabel = false">取消</el-button>
                </span>
                <!--            </template>-->
            </el-main>
        </el-drawer>
        <!--        标签新增/修改弹窗-->
        <el-drawer :append-to-body="true" :visible.sync="showColor" :title="labelTitle" class="form-inline" size="479px" :inline="true">
            <el-form :inline="true" :model="colorObj" class="demo-form-inline" style="margin-top: 42px;display:flex">
                <div class="search">
                    <div class="flex">
                        <div class="label">标签文字</div>
                        <el-form-item>
                            <el-input style="width: 312px" placeholder="请输入标签文字" v-model="colorObj.BQWZ"></el-input>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <div class="label">标签颜色</div>
                        <el-form-item>
                            <el-color-picker @change="changeColor" v-model="colorObj.BQYS">
                                <!--                                <div class="labels" :style="{background:colorObj.color}"></div>-->
                            </el-color-picker>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <span class="dialog-footer">
                <el-button type="primary" @click="saveColor">保存</el-button>
                <el-button @click="showColor = false">取消</el-button>
            </span>
        </el-drawer>
        <!--        列表卡片-->
        <el-drawer :visible.sync="showCard" :title="cardTitle" size="1133px" :inline="true">
            <el-form :inline="true" :model="detail" ref="detailForm"  class="form-inline" v-loading="uploadLoading">
                <div class="wrap">
                    <table style="text-align:center">
                        <tr>
                            <td>
                                <div class="label2">项目名称</div>
                            </td>
                            <td colspan="3">
                                <el-form-item>
                                    <el-input style="width: 572px;height: 32px" size="medium" v-model="detail.XMMC" placeholder="项目名称"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <div class="label2">路线编号</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.LXBH}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label2">所在地市</div>
                            </td>
                            <td style="width: 220px">
                                <div class="cont">{{detail.SSDS}}</div>
                            </td>
                            <td>
                                <div class="label2">所属县</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.SSX}}</div>
                            </td>
                            <td>
                                <div class="label2">项目性质</div>
                            </td>
                            <td>
                                <div class="cont">{{detail.XMXZ}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label2">建设性质</div>
                            </td>
                            <td>
                                <div class="cont">
                                    <el-form-item>
                                        <el-select style="width: 205px" placeholder="请选择项目类别" v-model="detail.JSXZ">
                                            <el-option label="新建" value="新建"></el-option>
                                            <el-option label="升级改造" value="升级改造"></el-option>
                                            <el-option label="原级改造" value="原级改造"></el-option>
                                            <el-option label="路面改造" value="路面改造"></el-option>
                                            <el-option label="改扩建" value="改扩建"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </td>
                            <td>
                                <div class="label2">项目类型</div>
                            </td>
                            <td>
                                <div class="cont">12121</div>
                            </td>
                            <td>
                                <div class="label2">建设类别</div>
                            </td>
                            <td>
                                <div class="cont">项目性质</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label2">开工年</div>
                            </td>
                            <td>
                                <el-form-item>
                                    <el-date-picker style="width:205px" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="detail.KGN" type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                            <td>
                                <div class="label2">完工年</div>
                            </td>
                            <td>
                                <el-form-item>
                                    <el-date-picker style="width: 205px" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="detail.WGN" type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                            <td>
                                <div class="label2">建设规模高速（公里）</div>
                            </td>
                            <td>
                                <el-form-item prop="JSGMGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                    <el-input style="width: 205px;height: 32px" size="medium" v-model="detail.JSGMGS" placeholder="建设规模高速"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label2">建设规模一级（公里）</div>
                            </td>
                            <td>
                                <el-form-item prop="JSGMYJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                    <el-input style="width: 205px;height: 32px" size="medium" v-model="detail.JSGMYJ" placeholder="建设规模一级"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <div class="label2">建设规模二级（公里）</div>
                            </td>
                            <td>
                                <el-form-item  prop="JSGMEJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                    <el-input style="width: 205px;height: 32px" size="medium" v-model="detail.JSGMEJ" placeholder="建设规模二级"></el-input>
                                </el-form-item>
                            </td>
                            <td>
                                <div class="label2">建设规模三级（公里）</div>
                            </td>
                            <td>
                                <el-form-item  prop="JSGMSJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                    <el-input style="width: 205px;min-height: 32px" size="medium" v-model="detail.JSGMSJ" placeholder="建设规模三级"></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                    </table>
<!--                    <div class="flex" style="margin: 18px 0 12px">-->
<!--                        <div class="slfj">矢量附件</div>-->
<!--                        <div class="star">* 以公路编号和路段命名，统一采用2000国家大地坐标系，标明中央子午线</div>-->
<!--                    </div>-->
<!--                    <table style="text-align:center">-->
<!--                        <tr>-->
<!--                            <td>-->
<!--                                <div class="label2"></div>-->
<!--                            </td>-->
<!--                            <td class="label3">-->
<!--                                公路中心线平曲线要素-->
<!--                            </td>-->
<!--                            <td class="label3">-->
<!--                                <div>公路用地图边线DXF格式电子版文件</div>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="label2">-->
<!--                                <div>规划选址</div>-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <div class="flex fjWrap">-->
<!--                                    <el-form-item>-->
<!--                                        <label class="fright">-->
<!--                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button&#45;&#45;primary el-button&#45;&#45;small">上传</i>-->
<!--                                            <input style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'GHDZZXX')" />-->
<!--                                        </label>-->
<!--                                    </el-form-item>-->
<!--                                    <div>-->
<!--                                        <div class="file" v-for="(item,index) in detail.GHDZZXX" :key="index">-->
<!--                                            <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                                <img src="../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff">{{item.name}} </span>-->
<!--                                            </a>-->
<!--                                            <i style="color: #666666" class="el-icon-delete" @click.stop="deleted(index,'GHDZZXX')"></i>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <div class="flex fjWrap">-->
<!--                                    <el-form-item>-->
<!--                                        <label class="fright">-->
<!--                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button&#45;&#45;primary el-button&#45;&#45;small">上传</i>-->
<!--                                            <input style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'GHDZYDT')" />-->
<!--                                        </label>-->
<!--                                    </el-form-item>-->
<!--                                    <div>-->
<!--                                        <div class="file" v-for="(item,index) in detail.GHDZYDT" :key="index">-->
<!--                                            <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                                <img src="../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff">{{item.name}} </span>-->
<!--                                            </a>-->
<!--                                            <i style="color: #666666" class="el-icon-delete" @click.stop="deleted(index,'GHDZYDT')"></i>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="label2">-->
<!--                                <div>工可研</div>-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <div class="flex fjWrap">-->
<!--                                    <el-form-item>-->
<!--                                        <label class="fright">-->
<!--                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button&#45;&#45;primary el-button&#45;&#45;small">上传</i>-->
<!--                                            <input style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'GKYZXX')" />-->
<!--                                        </label>-->
<!--                                    </el-form-item>-->
<!--                                    <div>-->
<!--                                        <div class="file" v-for="(item,index) in detail.GKYZXX" :key="index">-->
<!--                                            <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                                <img src="../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff">{{item.name}} </span>-->
<!--                                            </a>-->
<!--                                            <i style="color: #666666" class="el-icon-delete" @click.stop="deleted(index,'GKYZXX')"></i>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <div class="flex fjWrap">-->
<!--                                    <el-form-item>-->
<!--                                        <label class="fright">-->
<!--                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button&#45;&#45;primary el-button&#45;&#45;small">上传</i>-->
<!--                                            <input style="width: 72px" type="file" v-show="false" name="file" ref="file"  id="fileInput" multiple="multiple" @change="onSubmit($event,'GKYYDT')" />-->
<!--                                        </label>-->
<!--                                    </el-form-item>-->
<!--                                    <div>-->
<!--                                        <div class="file" v-for="(item,index) in detail.GKYYDT" :key="index">-->
<!--                                            <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                                <img src="../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff">{{item.name}} </span>-->
<!--                                            </a>-->
<!--                                            <i style="color: #666666" class="el-icon-delete" @click.stop="deleted(index,'GKYYDT')"></i>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="label2">-->
<!--                                <div>初步设计</div>-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <div class="flex fjWrap">-->
<!--                                    <el-form-item>-->
<!--                                        <label class="fright">-->
<!--                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button&#45;&#45;primary el-button&#45;&#45;small">上传</i>-->
<!--                                            <input style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'CBSJZXX')" />-->
<!--                                        </label>-->
<!--                                    </el-form-item>-->
<!--                                    <div>-->
<!--                                        <div class="file" v-for="(item,index) in detail.CBSJZXX" :key="index">-->
<!--                                            <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                                <img src="../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff">{{item.name}} </span>-->
<!--                                            </a>-->
<!--                                            <i style="color: #666666" class="el-icon-delete" @click.stop="deleted(index,'CBSJZXX')"></i>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <div class="flex fjWrap">-->
<!--                                    <el-form-item>-->
<!--                                        <label class="fright">-->
<!--                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button&#45;&#45;primary el-button&#45;&#45;small">上传</i>-->
<!--                                            <input style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'CBSJYDT')" />-->
<!--                                        </label>-->
<!--                                    </el-form-item>-->
<!--                                    <div>-->
<!--                                        <div class="file" v-for="(item,index) in detail.CBSJYDT" :key="index">-->
<!--                                            <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                                <img src="../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff">{{item.name}} </span>-->
<!--                                            </a>-->
<!--                                            <i style="color: #666666" class="el-icon-delete" @click.stop="deleted(index,'CBSJYDT')"></i>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="label2">-->
<!--                                <div>施工图设计</div>-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <div class="flex fjWrap">-->
<!--                                    <el-form-item>-->
<!--                                        <label class="fright">-->
<!--                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button&#45;&#45;primary el-button&#45;&#45;small">上传</i>-->
<!--                                            <input style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'SGTSJZXX')" />-->
<!--                                        </label>-->
<!--                                    </el-form-item>-->
<!--                                    <div>-->
<!--                                        <div class="file" v-for="(item,index) in detail.SGTSJZXX" :key="index">-->
<!--                                            <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                                <img src="../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff">{{item.name}} </span>-->
<!--                                            </a>-->
<!--                                            <i style="color: #666666" class="el-icon-delete" @click.stop="deleted(index,'SGTSJZXX')"></i>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <div class="flex fjWrap">-->
<!--                                    <el-form-item>-->
<!--                                        <label class="fright">-->
<!--                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button&#45;&#45;primary el-button&#45;&#45;small">上传</i>-->
<!--                                            <input style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'SGTSJYDT')" />-->
<!--                                        </label>-->
<!--                                    </el-form-item>-->
<!--                                    <div>-->
<!--                                        <div class="file" v-for="(item,index) in detail.SGTSJYDT" :key="index">-->
<!--                                            <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                                <img src="../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff">{{item.name}} </span>-->
<!--                                            </a>-->
<!--                                            <i style="color: #666666" class="el-icon-delete" @click.stop="deleted(index,'SGTSJYDT')"></i>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                    </table>-->
                </div>
            </el-form>
            <span class="dialog-footer">
                <el-button type="primary" @click="saveDetail">保存</el-button>
                <el-button @click="showCard = false">取消</el-button>
            </span>
        </el-drawer>
        <el-dialog :title="dialogTableVisibleData.XMMC" :visible.sync="dialogTableVisibleMap" class="mapdialog mapdialog1">
            <Map v-if="dialogTableVisibleMap" :dialogData="dialogTableVisibleData" />
        </el-dialog>
    </div>
</template>

<script>
    import Map from "./AMap/Box3";
    import PlanForm from "./MapPlantForm/PlanForm";
    import ZJJY from "./MapPlantForm/ZJJY";
import Carding from "../components/Query/plan/Carding";
import compareMap from "./CompareMap/Index";
import store from "../store";
export default {
    name: "plantForm",
    components: { Carding,PlanForm,ZJJY ,Map,compareMap},
    data() {
        return {
            jslb:'',
            ID:'',
            uploadLoading:false,
            baseUrl: "http://82.156.50.94:8002/",
            cardTitle: "",
            detail: {
                FJ: [{ url: "212121", name: "133231232" }],
            },
            labelTitle: "新增标签",
            colorObj: {},
            showCard: false, //卡片
            showColor: false, //颜色
            labelForm: {},
            labelList: [
                { color: "red", name: "十四五重点项目" },
                { color: "green", name: "十四五重点项目" },
                { color: "#d6000f", name: "十四五重点项目" },
            ],
            showLabel: false, //标签
            tableHeight: document.body.offsetHeight - 220, //表格高度
            tagHeight: document.body.offsetHeight - 185, //表格高度
            tableData: [],
            xmmc: "",
            region: [""], //已选中行政区划
            regions: [
                {
                    value: "",
                    label: "黑龙江省",
                },
            ],
            pageSize: 30, //每页记录数
            currentPage: 1,
            total: 0,
            activeIndex: 1,
            active: 0,
            saveTag:[],
            tab: ["项目梳理", "项目选址", "地方确认", "成果确认","套图比对"],
            jslbList: [{value: "高速公路", label: "高速公路", disabled: false},{value: "普通国道", label: "普通国道", disabled: false},{value: "普通省道", label: "普通省道", disabled: false}],
            jsxlList: [{value: "十四五", label: "十四五", disabled: false},{value: "2026-2035年", label: "2026-2035年", disabled: false}],
            jsxzList: [{value:"新建",label:"新建",disabled:false},{value:"升级改造",label:"升级改造",disabled:false},{value:"原级改造",label:"原级改造",disabled:false},{value:"路面改造",label:"路面改造",disabled:false},{value:"改扩建",label:"改扩建",disabled:false}],
            zjyjList: [{value:"1",label:"通过",disabled:false},{value:"2",label:"不通过",disabled:false},{value:'3',label:"未审核",disabled:false}],
            qrhList: [{value:"1",label:"已上传",disabled:false},{value:"2",label:"未上传",disabled:false}],
            checkAllJslb: false, //建设类别
            checkAllJsxl: false, //序列
            checkAllZjyj: false, //意见
            checkAllJsxz: false, //性质
            isIndeterminateJslb: true,
            isIndeterminateJsxl: true,
            isIndeterminateJsxz: true,
            isIndeterminateZjyj: true,
            checkedJslb: [], //建设类别
            checkedJsxl: [], //建设序列
            checkedJsxz: [], //建设性质
            checkedZjyj: [], //专家意见
            checkedQrh: [], //见确认函
            checkedLabel: [], //见确认函
            chek: [],
            params: {},
            editColorStatus: true,
            sortData: {
                field: "",
                sort: "asc",
            },
            dialogTableVisibleMap:false,
            dialogTableVisibleData:{}
        };
    },
    mounted() {
        this.getRegion();
        this.search();
        this.getTagList();
    },
    methods: {
        clearCondition() {
            this.region = [""];
            this.xmmc = "";
            this.xmnd = "";
            this.qqgzjz = [];
            this.gllx = "";
            this.checkedXmxz = [];
            this.checkedJslb = [];
            this.checkedJsxl = [];
            this.checkedJsxz = [];
            this.checkedZjyj = [];
            this.checkedQrh = [];
        },
        showMaps(row) {
            if (row.XH) {
                this.dialogTableVisibleData = row;
                this.dialogTableVisibleMap = true;
            }
        },
        getTagList(){
            var that = this
            this.http.post('/api/Land_road_tag_base/GetPageData',{page:1,rows:1000,}).then(res=>{
                console.log(res)
                this.labelList = res.rows
                this.labelList.map((item,index)=>{
                    item.label = item.BQWZ
                    item.value = item.ID
                    item.disabled = false
                })
                that.$nextTick(() => {
                    res.rows.forEach((item, index) => {
                        // if (!item.canChoose) {
                        //     // 默认被选中且禁用
                        //     this.$refs.multipleTable.toggleRowSelection(item, true);  // 如果只需要禁用 不需要这行
                        // }
                        //  selectReady 是默认要选中的数据
                        if (that.saveTag.length > 0) {
                            that.saveTag.forEach((obj, key) => {
                                if (item.ID == obj.TagId) { // 如果匹配上  代表当前角色下的用户  高亮选中
                                    console.log('22222222222')
                                    this.$refs.multipleTable.toggleRowSelection(item);
                                }
                            })
                        }
                    })
                    // this.chek = rows.TagList

                })

            })
        },
        changeSort(val) {
            console.log(val)
            if (val.order) {
                this.sortData = {
                    field: val.prop,
                    sort: val.order == "ascending" ? "asc" : "desc",
                };
                if (val.prop == "SSDS") {
                    this.sortData.field = "DSBM";
                }
                if (val.prop == "SSX") {
                    this.sortData.field = "QXBM";
                }
            } else {
                this.sortData = {
                    field: "",
                    sort: "asc",
                };
            }
            this.tableData = [];
            this.search();
        },
        checkNum  (rule, value, callback)  {
            console.log(rule,value)
            if (!value) {
                callback();
            }
            var reg =  /^\d+(\.\d+)?$/
            setTimeout(() => {
                if (value && !reg.test(value)) {
                    callback(new Error('请输入数字值'));
                } else {
                    callback();
                }
            }, 0);
        },
        closeWin() {
            console.log(123);
            window.close();
        },
        dealSaveFj(data){
            var pfwjString = []
            data.map((item)=>{
                pfwjString.push(item.url+item.name)
            })
            console.log( pfwjString.join(';'))
            return pfwjString.join(';')
        },
        onSubmit(e, type) {
            console.log(type,this.detail[type])
            this.uploadLoading = true;
            let file = e.target.files;
            console.log(file);
            let form = new FormData();
            let arrs = [];
            console.log(file);
            file.forEach((element) => {
                form.append("fileInput", element);
                arrs.push(element.name);
            });
            this.http
                .post("/api/View_land_road/upload", form)
                .then((res) => {
                    this.uploadLoading = false;
                    arrs.forEach((v) => {
                        // this.details[type].push(res.data + v);
                        this.detail[type].push({ url: res.data, name: v });
                    });
                    // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                });
            console.log(this.detail)
        },
        saveDetail() {
            var params = JSON.parse(JSON.stringify(this.detail))
            params.JSGMEJ = Math.floor( params.JSGMEJ)
            params.JSGMGS = Math.floor( params.JSGMGS)
            params.JSGMSJ = Math.floor( params.JSGMSJ)
            params.JSGMYJ = Math.floor( params.JSGMYJ)
            params.GHDZZXX = this.dealSaveFj(params.GHDZZXX)
            params.GHDZYDT = this.dealSaveFj(params.GHDZYDT)
            params.GKYYDT =this.dealSaveFj(params.GKYYDT)
            params.GKYZXX =this.dealSaveFj(params.GKYZXX)
            params.CBSJYDT =this.dealSaveFj(params.CBSJYDT)
            params.CBSJZXX =this.dealSaveFj(params.CBSJZXX)
            params.SGTSJYDT =this.dealSaveFj(params.SGTSJYDT)
            params.SGTSJZXX =this.dealSaveFj(params.SGTSJZXX)
            console.log(params,params.ID )
            if(params.ID == null){
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        this.http.post('/api/Land_road/AddLandRoad',params).then(res=>{
                            console.log(res)
                            if(res.status){
                                this.$message.success('保存成功')
                                this.showCard = false
                                this.search()
                            }else{
                                this.$message.error(res.message)

                            }
                        })
                    }
                })
            }else{
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        this.http.post('/api/Land_road/UpdateLandRoad',params).then(res=>{
                            console.log(res)
                            if(res.status){
                                this.showCard = false
                                this.$message.success('保存成功')
                                this.search()
                            }else{
                                this.$message.error(res.message)

                            }
                        })
                    }
                })
            }


        },
        deleted(index, type) {
            this.detail[type].splice(index, 1);
        },
        openDetailsEdit(row) {
            console.log(row,'我是row')
            this.cardTitle = row.XMMC;
            if(row.ID){
                this.http.post('/api/Land_road/getItemById?id='+row.ID,{}).then(res=>{
                    console.log(res)
                    this.detail = res
                    this.detail.GHDZZXX = this.dealFj(this.detail.GHDZZXX)
                    this.detail.GHDZYDT = this.dealFj(this.detail.GHDZYDT)
                    this.detail.GKYYDT = this.dealFj(this.detail.GKYYDT)
                    this.detail.GKYZXX = this.dealFj(this.detail.GKYZXX)
                    this.detail.CBSJYDT = this.dealFj(this.detail.CBSJYDT)
                    this.detail.CBSJZXX = this.dealFj(this.detail.CBSJZXX)
                    this.detail.SGTSJYDT = this.dealFj(this.detail.SGTSJYDT)
                    this.detail.SGTSJZXX = this.dealFj(this.detail.SGTSJZXX)
                })
            }else{
                this.detail = row
                this.detail.GHDZZXX = []
                this.detail.GHDZYDT = []
                this.detail.GKYYDT = []
                this.detail.GKYZXX = []
                this.detail.CBSJYDT = []
                this.detail.CBSJZXX = []
                this.detail.SGTSJYDT = []
                this.detail.SGTSJZXX = []
            }
            this.showCard = true;

        },
        addLabel() {
            this.colorObj = {};
            this.labelTitle = "新增标签";
            this.editColorStatus = true
            this.showColor = true;
        },
        saveColor() {
            if(this.colorObj.BQWZ == '' || this.colorObj.BQWZ == undefined){
                this.$message.error('请输入标签文字')
                return false
            }
            if(this.colorObj.BQYS == '' || this.colorObj.BQYS == undefined){
                this.$message.error('请选择颜色')
                return false
            }
            console.log(this.ID)
            var params = {
                id:'00000000-0000-0000-0000-000000000000',
                bqwz:this.colorObj.BQWZ,
                bqys:this.colorObj.BQYS
            }
            if(this.editColorStatus){
                params.id =  '00000000-0000-0000-0000-000000000000'
            }else{
                params.id =  this.colorObj.ID
            }
            if(this.editColorStatus){
                this.http.post("/api/Land_road_tag_base/AddLandRoadTagBase", params).then((res) => {
                    if(res.status){
                        this.$message.success('标签添加成功')
                        this.showColor = false
                        this.getTagList()
                        console.log( this.labelList)
                        // this.showLabel = false
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }else{
                this.http.post("/api/Land_road_tag_base/UpdateLandRoadTagBase", params).then((res) => {
                    if(res.status){
                        this.$message.success('保存成功')
                        this.showColor = false
                        this.getTagList()
                        // this.showLabel = false
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }
            console.log("保存颜色");
        },
        changeColor(val) {
            console.log(val);
        },
        chooseColor(row) {
            console.log(row);
            this.labelTitle = "编辑标签";
            this.colorObj = JSON.parse(JSON.stringify(row));
            this.showColor = true;
        },
        openLabel(rows) {
            var that = this
            console.log(rows)
            this.ID = rows.SSWGHXMID
            console.log(this.SSWGHXMID)
            this.showLabel = true;
            this.saveTag = rows.TagList
            this.editColorStatus = false
            this.getTagList()
           if(!rows.ID){
                       this.http.post('/api/Land_road/AddLandRoad',rows).then(res=>{
                           console.log(res)
                           if(res.status){
                               this.showLabel = true;
                               this.search()
                           }else{
                               this.$message.error(res.message)

                           }
                       })
           }
        },
        saveLable() {
            console.log(this.chek)
            this.chek.map((item,index)=>{
                item.TagId = item.ID
            })
            // this.getTagList()
            this.http.post("/api/Land_road_tag/BindLandRoadTag", {xmid:this.ID,tagList:this.chek}).then((res) => {
                if(res.status){
                    this.search()
                    this.$message.success('绑定成功')
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        deletedLabel(row) {
            console.log(row)
            this.http.post('/api/Land_road_tag_base/DeleteLandRoadTagBase',row).then(res=>{
                if(res.status){
                    this.$message.success('删除成功')
                    this.getTagList()
                    this.search()
                    // this.showColor = false
                    // this.showLabel = false
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        checkSelectable(val) {
            this.chek = val;
        },
        selectLabel(val) {
            console.log(val);
            this.chek = val;
        },
        getRegion() {
            var postData = { SSDS: "", SSX: "" };
            this.http
                .post("/api/Base_area/getAdminDivTree", postData)
                .then((res) => {
                    console.log(res);
                    this.regions = res;
                });
        },
        //附件处理
        dealFj(data){
            console.log(data)
            var a = []
            if(data != null && data != undefined && data != ''){
                data = data.split(';')
                data.map((subItem)=>{
                    subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                    a.push(subItem)
                })
                return  a
            }else {
                return  []
            }

            // if (data != null || data != undefined){
            //  return  {url:data.substring(0,data.lastIndexOf("/") + 1),name:data.substring(data.lastIndexOf("/") + 1,data.length)}
            // }else{
            //     return []
            // }
        },
        //查询
        search() {
            console.log("qingqiuzomg");
            if(this.active != 0){
                this.params = this.postData();
                // this.$refs.mychild.search();
                return false
            }
            this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
            this.http
                .post(
                    "/api/View_land_road/GetData",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    this.regions = [{ value: "", label: "黑龙江省" }];
                    this.getRegion();
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;
                    }
                });
        },
        postData() {
            var ssds = "";
            var ssx = "";
            if (this.region.length > 0) {
                ssds = this.region[0];
                if (this.region.length > 1) {
                    ssx = this.region[1];
                }
            }

            //项目名称
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };

            //所属地市
            var query_ssds = {
                Name: "DSBM",
                Value: ssds,
                DisplayType: "Equal",
            };
            //所属县
            var query_ssx = {
                Name: "QXBM",
                Value: ssx,
                DisplayType: "Equal",
            };
            //建设类别
            var jslbArray = [];
            this.checkedJslb.forEach((element) => {
                jslbArray.push(element);
            });
            var query_jslb = {
                Name: "JSLB",
                Value: jslbArray.join(','),
                DisplayType: "checkbox",
            };
            //确认函
            var qrhArray = [];
            this.checkedQrh.forEach((element) => {
                qrhArray.push(element);
            });
            var query_qrh = {
                Name: "QRHSFQR",
                Value: qrhArray.join(','),
                DisplayType: "checkbox",
            };
            //建设序列
            var jsxlArray = [];
            this.checkedJsxl.forEach((element) => {
                jsxlArray.push(element);
            });
            var query_jsxl = {
                Name: "JSXL",
                Value: jsxlArray.join(","),
                DisplayType: "checkbox",
            };
            //建设性质
            var jsxzArray = [];
            this.checkedJsxz.forEach((element) => {
                jsxzArray.push(element);
            });
            var query_jsxz = {
                Name: "JSXZ",
                Value: jsxzArray.join(","),
                DisplayType: "checkbox",
            };
            //专家意见
            var zjykArray = [];
            this.checkedZjyj.forEach((element) => {
                zjykArray.push(element);
            });
            var query_zjyj = {
                Name: "ZJYJ",
                Value: zjykArray.join(","),
                DisplayType: "checkbox",
            };
            //标签
            var labelArray = [];
            this.checkedLabel.forEach((element) => {
                element = "'"+element+"'"
                labelArray.push(element);
            });
            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: this.sortData.field,
                Order: this.sortData.sort,
                Value:labelArray.join(","),
                wheres: JSON.stringify([
                    query_xmmc,
                    query_ssds,
                    query_ssx,
                    query_jslb,
                    query_jsxl,
                    query_jsxz,
                    query_zjyj,
                    query_qrh
                ]),
            };
            return postData;
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
        download(url, fileName) {
            //下载导出的文件
            let xmlResquest = new XMLHttpRequest();
            xmlResquest.open("GET", url, true);
            xmlResquest.setRequestHeader("Content-type", "application/json");
            xmlResquest.setRequestHeader(
                "Authorization",
                store.getters.getToken()
            );
            let elink = document.createElement("a");
            xmlResquest.responseType = "blob";
            xmlResquest.onload = function(oEvent) {
                if (xmlResquest.status != 200) {
                    this.$error("下载文件出错了..");
                    return;
                }
                let content = xmlResquest.response;
                elink.download = fileName; //+".xlsx";
                // elink.style.display = "none";
                let blob = new Blob([content]);
                elink.href = URL.createObjectURL(blob);
                // document.body.appendChild(elink);
                elink.click();
                //  document.body.removeChild(elink);
            };
            xmlResquest.send();
        },
        exportFile(url) {
            var a = this.dealFj(url)
            console.log(a)

            var that = this
            a.map((item,index)=>{
                this.http.post('/api/Land_road/ExportFile?fileName='+item.url+item.name,{}).then(res=> {
                    if (!res.status) {
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/View_Land_road/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    console.log(path)
                    var timestamp = new Date().getTime();
                   console.log(that.baseUrl + path + "?path=" + res.data)
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        item.name
                    )
                })
            })

        },
        exportData() {
            var that = this;
            this.http
                .post(
                    "/api/Plan_high_national_early/templateWrite",
                    this.postData()
                )
                .then((res) => {
                    if (!res.status) {
                        this.$message.error(res.message);
                        return false;
                    }
                    let path =
                        "/api/" + "Plan_high_national_early" + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        timestamp + ".xlsx"
                    );
                });
            // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
        },
        handleChange(value) {
            this.search();
        },
        changeTab(index) {
            console.log(index);
            this.active = index;
            if (index === 3) {
                console.log(this.postData());
                this.params = this.postData();
            }
        },
        //建设类别
        handleCheckedJslbChange(value) {
            let checkedCount = value.length;
            this.checkAllJslb = checkedCount === this.jslbList.length;
            this.isIndeterminateJslb =
                checkedCount > 0 && checkedCount < this.jslbList.length;
        },
        handleCheckAllJslbChange(val) {
            this.checkedJslb = val ? this.jslbList : [];
            this.isIndeterminateJslb = false;
        },
        //建设序列
        handleCheckedJsxlChange(value) {
            let checkedCount = value.length;
            this.checkAllJsxl = checkedCount === this.jsxlList.length;
            this.isIndeterminateJsxl =
                checkedCount > 0 && checkedCount < this.jsxlList.length;
        },
        handleCheckAllJsxlChange(val) {
            this.checkedJsxl = val ? this.jsxlList : [];
            this.isIndeterminateJsxl = false;
        },
        //建设性质
        handleCheckedJsxzChange(value) {
            let checkedCount = value.length;
            this.checkAllJsxz = checkedCount === this.jsxzList.length;
            this.isIndeterminateJsxz =
                checkedCount > 0 && checkedCount < this.jsxzList.length;
        },
        handleCheckAllJsxzChange(val) {
            this.checkedJsxz = val ? this.jsxzList : [];
            this.isIndeterminateJsxz = false;
        },
        //专家意见
        handleCheckedZjyjChange(value) {
            let checkedCount = value.length;
            this.checkAllZjyj = checkedCount === this.zjyjList.length;
            this.isIndeterminateZjyj =
                checkedCount > 0 && checkedCount < this.zjyjList.length;
        },
        handleCheckAllZjyjChange(val) {
            this.checkedZjyj = val ? this.zjyjList : [];
            this.isIndeterminateZjyj = false;
        },
    },
};
</script>
<style>
.el-button {
    font-style: normal;
}
</style>
<style scoped lang="less">
.fl-jus {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.label {
    margin-right: 8px;
    width: 126px;
    height: 42px;
    background: #fafafa;
    line-height: 42px;
    color: #000000;
    font-weight: 600;
    text-align: center;
}
.label2 {
    width: 145px;
    height: 36px;
    background: #fafafa;
    line-height: 36px;
    color: #000000;
    font-weight: 600;
    text-align: center;
}
.label3 {
    width: 478px;
    height: 36px;
    background: #fafafa;
    line-height: 36px;
    color: #000000;
    font-weight: 600;
    text-align: center;
}
.cont {
    width: 220px;
    height: 36px;
    text-align: center;
    line-height: 36px;
}
body {
    background: #ffffff !important;
}
.flex {
    display: flex;
    align-items: center;
}
.plant {
    width: 100%;
    height: 50px;
    background: #06a7ff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .top {
        height: 50px;
        .logo {
            width: 32px;
            height: 32px;
            margin: 0 12px 0 20px;
            img {
                width: 32px;
                height: 32px;
            }
        }
        .title {
            width: 360px;
            height: 26px;
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            color: #ffffff;
            opacity: 1;
            margin-right: 151px;
        }
        .nav {
            cursor: pointer;
            margin-right: 65px;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.88);
        }
        .active {
            font-weight: bold;
            color: #ffffff;
        }
    }
    .shutDown {
        font-size: 20px;
        margin-right: 18px;
        color: #ffffff;
    }
}
.zjyj {
    font-size: 12px;
    font-weight: bold;
    width: 52px;
    height: 20px;
    background: #67c23a;
    border-radius: 12px;
    line-height: 20px;
    color: #ffffff;
}
.edit {
    padding-right: 15px;
    border-right: 1px solid rgba(112, 112, 112, 0.1);
    font-size: 16px;
    color: #409eff;
}
.delete {
    font-size: 16px;
    color: #f56c6c;
}
.dialog-footer {
    width: 100%;
    height: 47px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    background: #f8f8f8;
    align-items: center;
    justify-content: center;
}
.labels {
    margin: 0 auto;
    width: 27px;
    height: 20px;
    position: relative;
    border-radius: 2px;
    &::after {
        content: "";
        width: 0;
        height: 0;
        border-radius: 2px;
        border-top: 10px solid transparent;
        border-right: 8px solid #fff;
        border-bottom: 10px solid transparent;
        position: absolute;
        top: 0;
        left: 19px;
    }
}
/deep/.el-color-picker {
    line-height: 60px;
}
/deep/.el-color-picker__color {
    border: none;
}
/deep/.el-color-picker__trigger {
    margin: 0 auto;
    width: 35px;
    height: 20px;
    position: relative;
    border-radius: 2px;
    padding: 0;
    border: none;
    &::after {
        content: "";
        width: 0;
        height: 0;
        border-radius: 2px;
        border-top: 10px solid transparent;
        border-right: 8px solid #fff;
        border-bottom: 10px solid transparent;
        position: absolute;
        top: 0;
        right: 0px;
    }
}
.bigLabels {
    margin-right: 13px;
    padding: 0px 15px 0px 6px;
    height: 20px;
    line-height: 20px;
    position: relative;
    border-radius: 2px;
    color: #ffffff;
    margin-top: 4px;
    &::after {
        content: "";
        width: 0;
        height: 0;
        border-radius: 2px;
        border-top: 10px solid transparent;
        border-right: 8px solid #fff;
        border-bottom: 10px solid transparent;
        position: absolute;
        top: 0;
        right: 0px;
    }
}
.wrap {
    padding: 17px 19px;
    /deep/.el-form-item {
        margin: 0;
    }
    /deep/.el-input__inner {
        height: 32px;
    }
    /deep/.el-input--medium .el-input__inner {
        height: 32px;
        line-height: 32px;
    }
    table {
        border-collapse: collapse;
    }
    td {
        border: 1px solid #dddddd;
        .cont {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.slfj {
    text-align: center;
    width: 98px;
    height: 32px;
    background: #409eff;
    opacity: 1;
    border-radius: 0px 16px 16px 0px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 32px;
    margin-right: 17px;
}
.star {
    font-size: 14px;
    font-weight: bold;
    color: #f56c6c;
}
.fjWrap {
    padding: 3px 13px;
    align-items: flex-start;
    .fright {
        margin-right: 12px;
    }
}
.file {
    margin-right: 10px;
    margin-top: 5px;
    padding: 0 6px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #b3d8ff;
    opacity: 1;
    border-radius: 4px;
    color: #409eff;
    a {
        max-width: 338px;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 32px;
        line-height: 32px;
    }
}

</style>
