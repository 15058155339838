<template>
    <div class="Map AMap" id="Map" style="height:800px;">
        <div id="viewDiv" class="viewDiv"></div>
        <div id="layersDiv" class="esri-widget" v-show="layerStyleControlShow">
            <div id="description">
                <span id="sliderValue">地图图层显隐</span>
            </div>
            <div id="legendDiv">
                <!-- 图层显隐 -->
                <LayersShow />
            </div>
        </div>

    </div>
</template>
<script>
import { loadCss, loadModules } from "esri-loader";
import LayersShow from "@/components/AMap/LayersShow.vue"; //图层列表

import {
    apiDomainCss,
    apiDomainJs,
    RestUrl,
    BgUrl,
    DiMaoUrl,
    YingXiangUrl,
    amapImageUrl,
    amapStreetUrl,
    tdtUrl,
    planUrl,
} from "../../map/url";
import {
    createTintLayer,
} from "../../map/methods";

// import "../../assets/mapcss/global.less";
import "../../assets/mapcss/mappop.less";
import "../../assets/mapcss/map.less";

export default {
    name: "App",
    props: ["dialogData"],
    components: {
        LayersShow,
    },
    data() {
        return {
            bgLayer: null,
            dmLayer: null,
            yxLayer: null,
            ldLayer: null,
            trafficLayer: null,
            planLayer: null,
            TintLayer: null,
            amapImageLayer: null,
            amapStreetLayer: null,
            tdtLayer: null,
            map: null,
            mapView: null,
            sceneView: null,
            activeView: null,
            legend: null,
            draw: null,
            loading: false, //正在加载中
            layerStyleControlShow: false, //图层显隐
            token: "", //GIS TOKEN
            measurement: null,
            webMercatorUtils: null,
            Graphic: null,
            QueryTask: null,
            Query: null,
            Point: null,
            watchUtils: null,
            IdentifyParameters: null,
            Extent: null,
            SpatialReference: null,
            Polygon: null,
            find: null,
            FindParameters: null,
        };
    },
    methods: {
        location(feature) {
            var that = this;
            var where = "";
            var url = planUrl;
            console.log(feature,'22222')
            if (feature.JSLB == "高速公路") {
                where = "XMMC = '" + feature.XMMC + "'";
                url = url + "/1";
            } else {
                where = "BZ = '" + feature.XMMC + "'";
                url = url + "/0";
            }
            var queryTask = new this.QueryTask(url);
            var query = new this.Query();
            query.where = where;
            query.outSpatialReference = { wkid: 3857 };
            query.returnGeometry = true;
            query.outFields = ["*"];
            queryTask.execute(query).then(that.showResultsLX);
        },
        //整线定位
        showResultsLX(featureSet) {
            if (featureSet.features.length <= 0) {
                this.$message.error("无查询结果");
                return false;
            }
            var resultFeatures = featureSet.features;
            for (var i in resultFeatures) {
                var graphic = resultFeatures[i];
                this.showPlanFeature({ feature: graphic });
                var fullExtent;
                if (i == 0) {
                    fullExtent = graphic.geometry.extent;
                } else {
                    fullExtent = fullExtent.union(graphic.geometry.extent);
                }
            }
            this.activeView.goTo(fullExtent);
            this.loading = false;
        },
        showPlanFeature(feature) {
            var graphic = feature.feature;
            let sym = {
                type: "simple-fill",
                color: [0, 0, 0, 0],
                outline: {
                    color: [240, 29, 195],
                    width: "4",
                },
            };
            graphic.symbol = sym;
            this.activeView.graphics.add(graphic);
        },
    },
    mounted() {
        loadCss(apiDomainCss);
        loadModules(
            [
                "esri/Map",
                "esri/views/MapView",
                "esri/views/SceneView",
                "esri/request",
                "esri/layers/WebTileLayer",
                "esri/layers/BaseTileLayer",
                "esri/layers/MapImageLayer",
                "esri/layers/TileLayer",
                "esri/geometry/Point",
                "esri/Graphic",
                "esri/tasks/QueryTask",
                "esri/rest/support/Query",
                "esri/geometry/SpatialReference",
                "esri/geometry/Extent",
                "esri/Color",
                "esri/tasks/IdentifyTask",
                "esri/rest/find",
                "esri/rest/support/FindParameters",
                "esri/rest/support/IdentifyParameters",
                "esri/views/2d/draw/Draw",
                "esri/geometry/Polygon",
                "esri/core/watchUtils",
                "esri/identity/IdentityManager",
                "esri/identity/ServerInfo",
                "esri/widgets/Legend",
                "esri/widgets/Measurement",
                "esri/geometry/support/webMercatorUtils",
            ],
            {
                url: apiDomainJs,
            }
        )
            .then(
                ([
                    Map,
                    MapView,
                    SceneView,
                    request,
                    WebTileLayer,
                    BaseTileLayer,
                    MapImageLayer,
                    TileLayer,
                    Point,
                    Graphic,
                    QueryTask,
                    Query,
                    SpatialReference,
                    Extent,
                    Color,
                    IdentifyTask,
                    find,
                    FindParameters,
                    IdentifyParameters,
                    Draw,
                    Polygon,
                    watchUtils,
                    IdentityManager,
                    ServerInfo,
                    Legend,
                    Measurement,
                    webMercatorUtils,
                ]) => {
                    this.webMercatorUtils = webMercatorUtils;
                    this.Graphic = Graphic;
                    this.QueryTask = QueryTask;
                    this.Query = Query;
                    this.Point = Point;
                    this.watchUtils = watchUtils;
                    this.IdentifyParameters = IdentifyParameters;
                    this.IdentifyTask = IdentifyTask;
                    this.Extent = Extent;
                    this.SpatialReference = SpatialReference;
                    this.Polygon = Polygon;
                    this.find = find;
                    this.FindParameters = FindParameters;
    console.log(this.dialogData);
                    this.location(this.dialogData);

                    //背景图层
                    this.bgLayer = new TileLayer({
                        url: BgUrl,
                        visible: true,
                    });

                    //地貌图层
                    this.dmLayer = new TileLayer({
                        url: DiMaoUrl,
                        visible: false,
                    });

                    //影像图层
                    this.yxLayer = new TileLayer({
                        url: YingXiangUrl,
                        visible: false,
                    });

                    //获取Token
                    // identityManager.tokenValidity = 160; //设置token过期时间720分钟
                    ServerInfo.serverString = RestUrl + "/arcgis/rest/services"; //这里配置ArcGIS Server的REST服务地址
                    ServerInfo.tokenServiceUrl =
                        RestUrl + "/arcgis/tokens/generateToken"; //由于GIS Server和Portal联合了，所以使用Portal的token生成地址
                    // serverInfo.shortLivedTokenValidity = 720;//设置token过期时间720分钟
                    var userInfo = { username: "zzUser", password: "gis_1234" }; //这里填写Portal的用户和密码
                    // console.log("serverInfo", serverInfo);
                    var that = this;
                    IdentityManager.generateToken(ServerInfo, userInfo).then(
                        function (data) {
                            that.token = data.token;
                            window.localStorage.gisToken = data.token;
                            //注册Token，注册之后，在Portal里的所有资源，只要该用户由权限访问，就可以直接使用，之前的所有安全服务请求都将会把token值作为参数发送到服务器端
                            IdentityManager.registerToken({
                                server: RestUrl + "/arcgis/rest/services",
                                token: data.token,
                            });


                            that.planLayer = new MapImageLayer({
                                url: planUrl,
                                sublayers: [
                                    {
                                        id: 1,
                                        visible: false,
                                    },
                                    {
                                        id: 0,
                                        visible: false,
                                    },
                                ],
                            });
                            that.hxLayers = new TileLayer({
                                url: "http://39.100.134.93:6080/arcgis/rest/services/hongxian/MapServer",
                                id: "hxLayers",
                                visible: true,
                            });
                            that.ntLayers = new TileLayer({
                                url: "http://39.100.134.93:6080/arcgis/rest/services/nongtian/MapServer",
                                id: "ntLayers",
                                visible: true,
                            });
                            that.map.addMany([
                                // that.ldLayer,
                                // that.trafficLayer,
                                // that.planLayer,
                                that.hxLayers,
                                that.ntLayers,
                            ]);

                        },
                        function (err) {
                            console.log(err);
                        }
                    );

                    //创建高德地图对象
                    this.TintLayer = createTintLayer(
                        BaseTileLayer,
                        Color,
                        request
                    );

                    //高德影像
                    this.amapImageLayer = new this.TintLayer({
                        urlTemplate: amapImageUrl,
                        tint: new Color("#004FBB"),
                        title: "高德",
                        visible: false,
                    });

                    //高德街区
                    this.amapStreetLayer = new this.TintLayer({
                        urlTemplate: amapStreetUrl,
                        tint: new Color("#004FBB"),
                        title: "高德",
                        visible: false,
                    });

                    //天地图影像
                    this.tdtLayer = new WebTileLayer({
                        urlTemplate: tdtUrl,
                        subDomains: [
                            "t0",
                            "t1",
                            "t2",
                            "t3",
                            "t4",
                            "t5",
                            "t6",
                            "t7",
                        ],
                        copyright: "天地图影像",
                        visible: false,
                    });

                    this.map = new Map({
                        layers: [
                            this.bgLayer,
                            this.dmLayer,
                            this.yxLayer,
                            this.amapImageLayer,
                            this.amapStreetLayer,
                            this.tdtLayer,
                        ],
                    });

                    this.mapView = new MapView({
                        map: this.map,
                        container: "viewDiv",
                        constraints: {
                            rotationEnabled: false, //禁止旋转
                        },
                    });

                    const titleDiv = document.getElementById("titleDiv");
                    this.mapView.ui.add(titleDiv, "top-left");

                    // Create SceneView with similar extent to MapView
                    this.sceneView = new SceneView({
                        map: this.map,
                    });

                    this.activeView = this.mapView;
                    this.measurement = new Measurement();

                    /*矩形*/
                    this.draw = new Draw({
                        view: this.mapView,
                    });

                    //测量初始化
                    this.activeView.set({
                        container: "viewDiv",
                    });
                    this.activeView.ui.add(this.measurement, "bottom-right");
                    this.measurement.view = this.activeView;

                    //点击空白关闭鼠标右键菜单
                    document
                        .getElementById("viewDiv")
                        .addEventListener("click", () => {
                            this.mouseMenuState("none");
                        });

                    //地图缩放监听
                    this.watchUtils.when(
                        this.activeView,
                        "extent",
                        this.animation
                    );
                    this.watchUtils.when(
                        this.activeView,
                        "extent",
                        this.poptemplate
                    );

                    this.mapView.ui.remove("attribution"); //移除底部ESRI logo
                    this.mapView.ui._removeComponents(["zoom"]); //移除默认ui
                }
            )
            .catch((err) => {
                console.error("err", err);
            });
    },
};
</script>
<style scoped lang="less">
#infoDiv {
    display: none;
    position: absolute;
    bottom: 15px;
    left: 15px;
}
#layersDiv input {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
}

#layersDiv {
    position: absolute;
    padding: 10px;
    width: 275px;
    top: 55px;
    right: 10px;
}
#sliderValue {
    margin-left: 10px;
    font-weight: bolder;
}

#legendDiv {
    width: 260px;
    margin-left: 10px;
    margin-top: 1px;
}
#description {
    padding: 10px 0 10px 0;
}
#titleDiv {
    background: none;
    box-shadow: 0 0 black;
    margin-top: 0px;
}

.panel-side {
    padding: 2px;
    width: 390px;
    height: 85%;
    position: absolute;
    background: white;
    top: 0;
    right: 0;
    margin-top: 50px;
    margin-right: 10px;
    z-index: 999;
}

#resultsDiv {
    overflow: auto;
    height: 100%;
}

.query_result {
    width: 376px;
    position: absolute;
    top: 60px;
    left: auto;
    width: 430px;
    margin-left: 15px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 1px 5px 0px rgba(2, 40, 97, 0.15);
    border-radius: 3px;
    flex-wrap: wrap;
    padding: 12px;

    .nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        .left_nav {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: rgba(0, 123, 255, 1);
                margin-right: 8px;

                &:first-child {
                    width: 4px;
                    height: 16px;
                    background: rgba(0, 123, 255, 1);
                    border-radius: 2px;
                }
            }
        }

        .right_nav {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;

            i {
                width: 18px;
                height: 16px;

                img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .main_result {
        width: 100%;
        height: 80vh;
        // display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: scroll;

        .item_result {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            // width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            .left_item {
                display: flex;
                justify-content: center;
                align-items: center;
                // margin-right: 15px;
                width: 25px;

                i {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 14px;
                        height: 16px;
                        cursor: pointer;
                    }
                }
            }

            .right_item {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
                width: 0;

                i {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .center_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 5px;
                width: calc(100% - 50px);

                > span {
                    font-size: 12px;
                    width: 300px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgba(126, 126, 126, 1);
                    line-height: 2em;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    &:first-child {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 1);
                        cursor: pointer;
                    }
                }
            }
        }
        .active {
            color: rgb(0, 143, 212);
            span {
                color: rgb(0, 143, 212) !important;
            }
        }
    }
}
</style>
