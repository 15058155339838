<template>
  <div class="danger">
      <el-collapse v-model="Collapse" >
        <el-collapse-item name="1">
          <template slot="title">
            <div class="title">基本信息</div>
          </template>
          <el-descriptions :column="1">
            <el-descriptions-item label="项目名称">
              {{ roadModel.XMMC!=' '?roadModel.XMMC:roadModel.BZ }}
            </el-descriptions-item>
            <el-descriptions-item label="路线编码">
              {{
              roadModel.LXBH != null && roadModel.LXBH != "" ? roadModel.LXBH : (roadModel.LXBM||'无')
              }}
            </el-descriptions-item>
            <el-descriptions-item label="所在地市">
              {{
              roadModel.SSDS != null && roadModel.SSDS != "" ? roadModel.SSDS : "无"
              }}
            </el-descriptions-item>
            <el-descriptions-item label="所在县市">
              {{
              roadModel.SSX != null && roadModel.SSX != "" ? roadModel.SSX : "无"
              }}
            </el-descriptions-item>

            <el-descriptions-item label="建设类别">
              {{ roadModel.JSLB }}
            </el-descriptions-item>
            <el-descriptions-item label="建设序列">
              {{
              roadModel.JSXZ != null && roadModel.JSXZ != "" ? roadModel.JSXZ : "无"
              }}
            </el-descriptions-item>
            <el-descriptions-item label="建设性质">
              {{
              roadModel.JSXZ != null && roadModel.JSXZ != "" ? roadModel.JSXZ : "无"
              }}
            </el-descriptions-item>
            1212


          </el-descriptions>
          <el-divider><div class="divider">建设规模</div></el-divider>
          <el-descriptions :column="1">
            <el-descriptions-item label="高速（公里）">
              {{  roadModel.JSGMGS != null && roadModel.JSGMGS != "" ? roadModel.JSGMGS : "无"}}
            </el-descriptions-item>
            <el-descriptions-item label="一级（公里）">
              {{
              roadModel.JSGMYJ != null && roadModel.JSGMYJ != "" ? roadModel.JSGMYJ : '无'
              }}
            </el-descriptions-item>
            <el-descriptions-item label="二级（公里）">
              {{
              roadModel.JSGMEJ != null && roadModel.JSGMEJ != "" ? roadModel.JSGMEJ : "无"
              }}
            </el-descriptions-item>
            <el-descriptions-item label="三级（公里）">
              {{
              roadModel.JSGMSJ != null && roadModel.JSGMSJ != "" ? roadModel.JSGMSJ : "无"
              }}
            </el-descriptions-item>

            <el-descriptions-item label="桥隧（数量）">
              {{ roadModel.GLLX }}
            </el-descriptions-item>
            1212


          </el-descriptions>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
           <div style="display: flex;align-items: center;justify-content: space-between;width: 300px">
             <div class="title">专家意见</div>
             <div class=status :style="{background:roadModel.ZJYJ == 1 ? '#67c23a' : roadModel.ZJYJ == 2 ? '#F56C6C' : '#909399'}">
               {{roadModel.ZJYJ == 1 ? '通过' : roadModel.ZJYJ == 2 ? '不通过' : '未审核' }}
             </div>
           </div>
          </template>

          <div style="display: flex;align-items: center;justify-content: space-between;margin: 10px 0 6px">
            <div class="detail"><span class="lineBlock"></span> 详情</div>
<!--            <div class="bj" @click="biaoji"><i class="el-icon-s-flag"></i>标记</div>-->
          </div>
          <template>
            <el-table :header-cell-class-name="cellClass"  ref="multipleTable" :data="suggestData"  border style="width: 100%">
              <el-table-column :width="50" prop="" label="" @click="reviewSquire">
                <template slot-scope="scope">
                 <i class="el-icon-location" style="color: #409EFF" @click="reviewSquire(scope.row)"></i>
                </template>
              </el-table-column>
              <el-table-column  prop="ZJYJ" label="专家意见"  show-overflow-tooltip></el-table-column>
              <el-table-column  prop="DSFKNR" label="地市反馈" show-overflow-tooltip></el-table-column>
<!--              <el-table-column  :width="50">-->
<!--                <template slot-scope="scope">-->
<!--                 <i class="el-icon-delete" style="color: #F56C6C" @click="deleted(scope.row)"></i>-->
<!--                </template>-->
<!--              </el-table-column>-->
            </el-table>
          </template>
        </el-collapse-item>
      </el-collapse>
  </div>
</template>
<script>
  export default {
  props: ["roadModel","sugList"],
    data(){
    return{
      textarea:'',
      radio:'',
      suggestData:[],
      Collapse:['1','2']
    }
  },
    watch:{
      sugList(val){
        console.log(val,'1212121212')
        this.suggestData = val
      }
    },
  methods:{
    deleted(row){
      this.http.post('/api/Land_road_flag/DeleteLandRoadFlag',row).then(res=>{
        if (res.status){
          this.$message.success('删除成功')
          this.$parent.getList(this.roadModel.SSWGHXMID)
        }else{
          this.$message.warning(res.message)
        }
      })

    },
    biaoji(){
      this.$parent.drawSquire()
      // this.$parent.showSketch()
    },
    reviewSquire(params){
      console.log(params,2222222222222)
      // this.$parent.reviewSquire()
      this.$emit('reviewSquire',params)


    },
    closeEdit(){},
    cellClass(row){
      if (row.columnIndex === 0) {
        return 'DisableSelection'
      }
    },
  },
  mounted(){
  console.log(this.sugList,'hhhhhhhh')
  }
};
</script>
<style scoped lang="less">
  .danger{
    /deep/.el-descriptions-item__content{
      line-height: 32px;
    }
  /deep/.el-descriptions-item__label:not(.is-bordered-label){
    line-height: 32px;
    color: #000;
    font-size: 14px;
    font-weight: bold;
  }
    .flex{
      display: flex;
      align-items: center;
    }
    .fl-jus{
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .label{
      margin-right: 8px;
      width: 168px;
      height: 42px;
      background: #FAFAFA;
      line-height: 42px;
      color: #000000;
      font-weight: 600;
      text-align: center;
    }
    /deep/.el-step__title.is-process{
      color: #409EFF !important;
      border-color: #409EFF !important;
    }
    .title{
      padding: 0 0px;
      min-width: 89px;
      height: 32px;
      background: #409EFF;
      opacity: 1;
      line-height: 32px;
      text-align: center;
      color: #fff;
    }
    .sort{
      width: 42px;
      height: 42px;
      line-height: 42px;
      background: #DCDFE6;
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      opacity: 1;
      text-align: center;
    }
    .label1{
      margin-right: 8px;
      width: 126px;
      height: 42px;
      background: #FAFAFA;
      line-height: 42px;
      color: #000000;
      font-weight: 600;
      text-align: center;
    }
    /deep/.el-collapse-item__header{
      background: #F2F8FE !important;
      padding: 0;
      height: 28px;
    }
    /deep/.el-collapse-item__content{
      padding: 10px 0 20px 0 !important;
    }
    .rowWrap{
      width: 538px;
      .row{
        margin-top: 12px;
        width: 536px;
        border-bottom: 1px solid #C0C4CC;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .sb{
          text-align: center;
          width: 107px;
          height: 28px;
          line-height: 28px;
          background: #B3D8FF;
          opacity: 1;
          border-radius: 0px 14px 0px 0px;
          font-size: 16px;
          font-weight: 600;
        }
        .add{
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #67C23A;
          i{
            font-size: 16px;
            margin-right: 8px;
          }
        }
      }

    }
    .zk{
      margin-bottom: 10px;
      border-bottom: 1px solid #C0C4CC;
      .sb{
        text-align: center;
        padding: 5px 18px;
        height: 28px;
        line-height: 28px;
        background: #B3D8FF;
        opacity: 1;
        border-radius: 0px 14px 0px 0px;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .footer{
      position: absolute;
      bottom: 0;
      right: 0;
      width: 1128px;
      height: 46px;
      background: #F8F8F8;
      display: flex;
      align-items: center;
      justify-content: center;
      .save{
        cursor: pointer;
        width: 74px;
        background: #409EFF;
        opacity: 1;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        margin-right: 47px;
      }
      .cancel{
        text-align: center;
        width: 74px;
        background: #FFFFFF;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        color: #666666;
      }
    }
  }
  .zw{
    width: 362px;
  }
  .addD{
    margin-top: 11px;
    width: 124px;
    height: 32px;
    background: #67C23A;
    opacity: 1;
    border-radius: 4px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    i{
      font-size: 16px;
      margin-right: 9px;
    }
  }
  .fl-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .photo{
      margin: 6px 0px;
      width: 520px;
      height: 251px;
      background: #FFFFFF;
      border: 1px dotted #666666;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #C0C4CC;
      img{
        width: 520px;
        height: 251px;
        border-radius: 10px;
      }
    }
  }
  .divider{
    text-align: center;
    width: 100px;
    height: 24px;
    background: rgba(189, 221, 255);
    border-radius: 10px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 24px;
    color: #000000;
  }
  .status{
    font-size: 12px;
    font-weight: bold;
    padding: 2px 10px;
    color: #fff !important;
    margin-right: 9px;
    height: 20px;
    line-height: 20px;
    background: rgba(245, 108, 108, 1);
    border-radius: 12px;
  }
  .button{
    margin-top: 8px;
    width: 74px;
    height: 32px;
    border-radius: 4px;
    span{
      display: block !important;
      line-height: 32px !important;

    }
  }
  .lineBlock{
    width: 3px;
    height: 14px;
    display: inline-block;
    background: rgba(64, 158, 255);
    margin-right: 6px;
  }
  .detail{
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }
  .bj{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 20px;
    background: #F79951;
    opacity: 1;
    cursor: pointer;
    color: #FFFFFF;
    font-weight: bold;
    border-radius: 4px;
  }
</style>
