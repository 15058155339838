<template>
    <div class="search">
      <div style="display:flex;align-items: center">
          <div class="input">
              <el-cascader @change="search" size="medium" v-model="region" :options="regions" placeholder="行政区划" :props="{ expandTrigger: 'hover' }" collapse-tags clearable>
              </el-cascader>
          </div>
          <div class="input">
              <!--            <el-select v-model="searchType" slot="prepend" placeholder="请选择">-->
              <el-cascader @change="search" size="medium" v-model="checkedJslb" :options="jslbList" placeholder="建设类别" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
              </el-cascader>
              <!--                <el-option label="建设类别(全部)" value=""></el-option>-->
              <!--                <el-option v-for="item in layers" :key="item.value" :label="item.name" :value="item.value"></el-option>-->
              <!--            </el-select>-->
          </div>
          <div class="input">
              <el-cascader @change="search" size="medium" v-model="checkedZjyj" :options="zjyjList" placeholder="专家意见" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
              </el-cascader>
          </div>
          <div class="input">
              <el-cascader @change="search" size="medium" v-model="checkedLabel" :options="labelList" placeholder="标签" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
              </el-cascader>
          </div>
      </div>
           <div class="mc" style="padding-left: 10px;padding-top: 5px">
               <el-input v-model="searchText" @change="search" placeholder="项目名称">
                   <i slot="prefix" class="el-input__icon el-icon-search"></i>
               </el-input>
           </div>
<!--        <el-button icon="el-icon-search" @click="search"></el-button>-->
        <!-- <el-input placeholder="请输入搜索内容" v-model="searchText" class="input-with-select">
            <el-select v-model="searchType" slot="prepend" placeholder="请选择" style="width:135px">
                <el-option label="建设类别(全部)" value=""></el-option>
                <el-option v-for="item in layers" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
            111
            <el-cascader size="medium" v-model="checkedXmxz" :options="xmxzList" placeholder="项目性质" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
            </el-cascader>
            <el-button slot="append" @click="search" icon="el-icon-search"></el-button>
        </el-input> -->
    </div>
</template>
<script>
export default {
    props:{
        reSearchStatus: { type: Boolean }
    },
    mounted() {
        this.getRegion();
        this.search();
        this.getTagList()
    },
    watch:{
        reSearchStatus:{
            handler(val){
                console.log('jinlaile',val)
                if (val){
                    this.search()
                }
            },
            immediate: true
        },
    },
    methods: {
        getTagList(){
            var that = this
            this.http.post('/api/Land_road_tag_base/GetPageData',{page:1,rows:1000,}).then(res=>{
                console.log(res)
                this.labelList = res.rows
                this.labelList.map((item,index)=>{
                    item.label = item.BQWZ
                    item.value = item.ID
                    item.disabled = false
                })
            })
        },

        search() {
            // if (this.searchText.trim() == "") {
            //   this.$toastr("请输入搜索内容");
            //   return false;
            // }
            var layerids = [];
            // if (this.searchType != "") {
            //     layerids = [this.searchType];
            // } else {
            //     this.layers.forEach(function (v) {
            //         layerids.push(v.value);
            //     });
            // }
            console.log(this.searchText);
            var ssds = "";
            var ssx = "";
            if (this.region.length > 0) {
                ssds = this.region[0];
                if (this.region.length > 1) {
                    ssx = this.region[1];
                }
            }
            //所属地市
            var query_ssds = {
                Name: "DSBM",
                Value: ssds,
                DisplayType: "Equal",
            };

            //所属县
            var query_ssx = {
                Name: "QXBM",
                Value: ssx,
                DisplayType: "Equal",
            };
            var query_xmmc = {
                Name: "XMMC",
                Value: this.searchText,
                DisplayType: "like",
            };
            //建设类别
            var query_jslb = {
                Name: "JSLB",
                Value:  this.checkedJslb.join(","),
                DisplayType: "checkbox",
            };
            //专家意见
            var zjyjArray = [];
            this.checkedZjyj.forEach((element) => {
                // xmxzArray.push(this.tranXmxz(element));
                zjyjArray.push(element);
            });
            var query_zjyj = {
                Name: "ZJYJ",
                Value: zjyjArray.join(","),
                DisplayType: "checkbox",
            };
            //标签
            var labelArray = [];
            this.checkedLabel.forEach((element) => {
                element = "'"+element+"'"
                labelArray.push(element);
            });
            console.log(labelArray,'labelArray')

            var postData = {
                page: 1,
                rows: 1000,
                Sort: this.sortData.field,
                Order: this.sortData.sort,
                Value:labelArray.join(","),
                wheres: JSON.stringify([
                    query_xmmc,
                    query_jslb,
                    query_zjyj,
                    query_ssds,
                    query_ssx
                ]),
            };
            this.$parent.reSearchSate()
            this.$parent.doFind(layerids, this.searchText.trim(), postData);
        },
        getRegion() {
            var postData = { DSBM: "", QXBM: "" };
            this.http
                .post("/api/Base_area/getAdminDivTree", postData)
                .then((res) => {
                    console.log(res);
                    this.regions = res;
                });
        },
    },
    data() {
        return {
            labelList:[],
            region: [""], //已选中行政区划
            regions: [
                {
                    value: "",
                    label: "黑龙江省",
                },
            ],
            sortData: {
                field: "",
                sort: "asc",
            },
            checkedJsxz: [],
            checkedJslb: [],
            checkedZjyj:[],
            checkedLabel:[],
            jslbList:[ { value: "高速公路", label: "高速公路", disabled: false },
                { value: "普通国道", label: "普通国道", disabled: false },
                { value: "普通省道", label: "普通省道", disabled: false },],
            zjyjList:[ { value: "1", label: "通过", disabled: false },
                { value: "2", label: "未通过", disabled: false },
                { value: "3", label: "未审核", disabled: false }],
            jsxzList: [
                { value: "新建", label: "新建", disabled: false },
                { value: "升级改造", label: "升级改造", disabled: false },
                { value: "原级改造", label: "原级改造", disabled: false },
                { value: "路面改造", label: "路面改造", disabled: false },
                { value: "改扩建", label: "改扩建", disabled: false },
            ],
            searchText: "",
            searchType: "",
            layers: [
                { name: "高速公路", value: "高速公路" },
                { name: "普通国道", value: "普通国道" },
                { name: "普通省道", value: "普通省道" },
            ],
        };
    },
};
</script>
<style lang="less" scoped>
.input {
    width: 120px;
}
.el-button {
    height: 36px !important;
    line-height: 1 !important;
}
.search {
     width: 360px;
    height: 41px;
    background: rgba(236, 245, 255);
}
.el-select {
    width: 150px;
}
/deep/ .mc .el-input__inner {
    color: #333;
    background-color: unset !important;
    width: 340px !important;
    height: 36px !important;
    border: 1px solid #dddddd !important;

    &::placeholder {
        color: #333;
    }
}
/deep/ .el-input__inner {
    color: #333 ;
    background-color: unset !important;
    width: 120px;
    height: 41px !important;
    border: none !important;
    &::placeholder {
        color: #333;
    }
}
/deep/ .el-input {
    width: 90px !important;
    height: 41px !important;
}
/deep/ .el-input__inner:focus{
    outline: unset !important;
}
.input-with-select .el-input-group__prepend {
    background-color: #fff;
}
.mc{
    background: rgba(255, 255, 255, 0.8);
    width: 360px;
    height: 45px;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;

}
/deep/.el-cascader__tags .el-tag{
    background:rgba(0,0, 0,0) !important;
    color: #409eff !important;
}
/* input {
  width: 200px;
  height: 30px;
}
button {
  margin-left: 10px;
  height: 30px;
  width: 60px;
} */
</style>
